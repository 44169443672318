import React from 'react'
import styled from '@emotion/styled'


interface TextBold {
    children: React.ReactChild
    className?: string
}


const TextBold: React.FC<TextBold> = ({ children, className }) => {
    const TextBoldStyle = styled.span({
        fontFamily: '"latoBold", arial'
    })
    
    return (
        <TextBoldStyle className={className}>{children}</TextBoldStyle>
    )
    
}

export default TextBold

