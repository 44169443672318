import AvatarEditor from 'react-avatar-editor'
import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import Button from '../ui/DS/button'

import { createAvatar } from '../../shared/api/routes'
import { S3_BASE_URL } from '../../shared/config'

type Avatar = {
    url: string,
    key: string
}

interface ProfilPictureEditor {
    uploadBtnLabel: string
    onSave: (avatar?: Avatar, confirmMsg?: string) => void
    onError: (errorMsg: string) => void
}

const ProfilPictureEditor: React.FC<ProfilPictureEditor> = ({ uploadBtnLabel, onSave, onError }) => {
    const [image, setImage] = useState<File | null>(null)
    const editorRef = useRef(null)

    const [loadingState, setLoadingState] = useState(false)

    const handlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files
        if (file)
            setImage(file[0])
    }


    const postImg = async (blob: Blob) => {
        try {
            setLoadingState(true)
            const formData = new FormData()
            formData.append('file', blob, image?.name)
            const response = await createAvatar(formData)
            if (response.ok) {
                const json = await response.json()
                setImage(null)
                setLoadingState(false)
                onSave({
                    url: `${S3_BASE_URL}/avatar/${image?.name}`,
                    key: (image as any).name
                }, json)
            } else {
                if (response.status === 401 || response.status === 403) {
                    window.location.href = "/connexion"
                }
                setLoadingState(false)
                setImage(null)
                onError("Erreur lors de la sauvegarde de l'image de profil")
            }
        } catch (e) {
            setLoadingState(false)
            setImage(null)
            onError("Erreur lors de la sauvegarde de l'image de profil")
        }
    }

    
    const onClickSave = () => {
        if (editorRef) {
            const ref = editorRef.current as any
            if (ref) {
                const canvas = ref.getImageScaledToCanvas()
                canvas.toBlob((blob: Blob) => {
                    postImg(blob)
                }, "image/jpeg")
            }
        }
    }

    return (
        <div>
            {image &&
                <UploaderWrapper>
                    <AvatarEditorWrapper>
                        
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={250}
                            height={250}
                            border={0}
                            color={[255, 255, 255, 0.6]} // RGBA
                            scale={1.2}
                            rotate={0}
                            borderRadius={250}
                        />
                    </AvatarEditorWrapper>
                    <Button disabled={loadingState} type='button' styleType='primary' onClick={onClickSave}>
                        {
                            loadingState ? "Sauvegarde en cours..." : "Sauvegarder l'image de profil"
                        }

                    </Button>
                </UploaderWrapper>
            }


            <CustomUploadButton htmlFor="file">
                <div>
                    {uploadBtnLabel}
                </div>
                <input disabled={loadingState} type="file" id="file" accept="images/jpeg" onChange={handlChange} />
            </CustomUploadButton>

        </div>
    )
}

export default ProfilPictureEditor

const CustomUploadButton = styled.label(({ theme }) => ({
    padding: theme.spacer.s,
    backgroundColor: "white",
    borderRadius: theme.radius.light,
    width: "325px",
    border: `2px dashed ${theme.colors.primary.regular}`,
    cursor: "pointer",
    marginBottom: theme.spacer.m,
    "& input": {
        display: "none"
    }
}))

const AvatarEditorWrapper = styled.div(({ theme }) => ({
    width: "250px",
    height: "250px",
    marginBottom: theme.spacer.m
}))

const UploaderWrapper = styled.div(({ theme }) => ({
    marginBottom: theme.spacer.l
}))