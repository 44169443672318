import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import InputField from '../forms/InputField'

interface SearchProps {
    callback: (value: string) => void
    result?: Ingredient[]
    addIngredient: (ingredient: any) => void
    clearSearch: () => void
}

type Ingredient = {
    id: string
    name: string
    plural: string
}

const Search: React.FC<SearchProps> = ({ callback, result, clearSearch, addIngredient }) => {
    const searchTimer = 500
    let searchTimeout: any
    const defaultSearch = {
        lock: false,
        value: ""
    }
    const [search, setSearch] = useState(defaultSearch)


    const handleChange = (event: any) => {
        if (searchTimeout) {
            window.clearTimeout(searchTimeout)
        }
        setSearch({ value: event.target.value, lock: true })
    }

    const searchRequest = (value: string) => {
        callback(value)
        setSearch({ ...search, lock: false })
    }

    const add = (ingredient: Ingredient) => {
        addIngredient(ingredient)
        setSearch(defaultSearch)
    }

    useEffect(() => {
        if (search.lock) {
            searchTimeout = setTimeout(() => {
                searchRequest(search.value)
            }, searchTimer)

        }
        return () => {
            window.clearTimeout(searchTimeout)
        }
    }, [search])

    return (
        <SearchWrapper>
            <FlexLine>
                <InputFieldStyle type="text" placeholder="Rechercher des ingrédients" name="search" id="search" onChange={handleChange} value={search.value} />
                <span className="btn btn-secondary">
                    <i className="bi bi-search"></i>
                </span>
            </FlexLine>
            <div>
                {
                    (result && result.length > 0) && 
                    <ResultSearchMenu>{result.map(item => <p className="dropdown-item" onClick={() => add(item)} key={item.id}>{item.name}</p>)}</ResultSearchMenu>
                }
            </div>
        </SearchWrapper>

    )
}

const SearchWrapper = styled.div({
    position: "relative"
})

const ResultSearchMenu = styled.div(({theme}) => ({
    position: "relative",
    padding: theme.spacer.s,
    border: `1px solid ${theme.colors.secondary.regular}`
}))

const FlexLine = styled.div(() => ({
    display: "flex",
    alignItems: "center"
}))

const InputFieldStyle = styled(InputField)(({theme}) => ({
    flexGrow: 1,
    "& input": {
        borderRadius: "5px 0 0 5px"
    }
}))

export default Search