import React, { Suspense } from 'react'
import Recipes from './pages/recipes'
import RecipeEdition from './pages/recipeEdition'
import Recipe from './pages/recipe'
import './App.css';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import MySubscriptions from './pages/mySubscribtions'
import MySubscribers from './pages/mySubscribers'
import PrivateRoute from './components/privateRoute'
import LoggedLayout from './pages/loggedLayout'
import MyAccount from './pages/myAccount'

const LoggedApp: React.FC = () => {
  
  return (
    <div className="App">
      <Router>
        <LoggedLayout>
          <Suspense fallback={"loading"}>
            <Switch>
              <PrivateRoute
                path="/amis/mesabonnements"
                exact={true}
                component={MySubscriptions}
              />
              <PrivateRoute
                path="/amis/mesabonnes"
                exact={true}
                component={MySubscribers}
              />
              <PrivateRoute
                path="/recettes"
                exact={true}
                component={Recipes}
              />
              <PrivateRoute
                path="/recettes/edition"
                exact={true}
                component={RecipeEdition}
              />
              <PrivateRoute
                path="/recettes/edition/:id"
                exact={true}
                component={RecipeEdition}
              />
              <PrivateRoute
                path="/recette/:id"
                exact={true}
                component={Recipe}
              />
              <PrivateRoute
                path="/mon-compte"
                exact={true}
                component={MyAccount}
              />
              <Redirect
                path="/*"
                to="/recettes"
              />
            </Switch>
          </Suspense>
        </LoggedLayout>
      </Router>
    </div>
  )
}

export default LoggedApp
