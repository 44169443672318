import { useReducer } from 'react'

type ToasterStatus = "error" | "success" | "info"
type RenderToaster = {
    status: ToasterStatus
    msg: string
}

interface State<T> {
    enableTranstion: boolean
    renderToaster?: {
        status: ToasterStatus
        msg: string
    }

}

type Toaster<T> = State<T> & { dispatch: React.Dispatch<Action<T>> }


type Action<T> =
    | { type: 'enableTransition', payload: boolean }
    | {
        type: 'renderToaster', payload: RenderToaster | undefined
    }

const useToaster = <T = unknown>(): Toaster<T> => {
    const initialState: State<T> = {
        enableTranstion: false,
        renderToaster: undefined,
    }
    const toasterReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case 'enableTransition':
                return { ...state, enableTranstion: action.payload }
            case 'renderToaster':
                return { ...state, enableTranstion: false, renderToaster: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(toasterReducer, initialState)

    return {
        ...state,
        dispatch
    }
}

export default useToaster