import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Theme } from '../../../theme'

type Types = "primary" | "secondary" | "tertiary"

interface Button {
    to: string
    type: Types
    children: any
    className?: string
}

const ButtonLink: React.FC<Button> = ({ children, type, to, className }) => {
    return (
        <ButtonStyled to={to} className={className} types={type}>{children}</ButtonStyled>
    )
}

const getBgColor = (type: Types, theme: Theme, isHover?: boolean ) => {
    switch (type) {
        case "primary": return isHover ? theme.colors.primary.light : theme.colors.primary.regular
        case "secondary": return isHover ? theme.colors.secondary.light : theme.colors.secondary.regular
        case "tertiary": return isHover ? theme.colors.tertiary.light : theme.colors.tertiary.regular
        default: return isHover ? theme.colors.primary.light : theme.colors.primary.regular
    }
}

const getColor = (type: Types, theme: Theme) => {
    switch (type) {
        case "primary": return theme.colors.secondary.regular
        case "secondary": return "white"
        case "tertiary": return "white"
        default: return theme.colors.secondary.regular
    }
}

const baseButtonStyle = styled(Link)(({theme}) => ({
    padding: "0.5rem 1rem",
    borderRadius: theme.radius.regular,
    transition: "all 0.2s ease-in ",
    textDecoration: "none",
    display: "inline-block",
    color: theme.colors.secondary.regular,
    cursor: "pointer",
    lineHeight: "normal",
    border: "2px solid transparent"
}))


const ButtonStyled = styled(baseButtonStyle)<{types: Types}>(({theme, types}) => ({
    backgroundColor: getBgColor(types, theme, false),
    color: getColor(types, theme),
    ":hover": {
        color: getColor(types, theme),
        backgroundColor: getBgColor(types, theme, true)
    },
    ":visited": {
        color: getColor(types, theme),
        backgroundColor: getBgColor(types, theme, false)
    }
}))

export default ButtonLink