import React from 'react'
import { User } from '../../shared/models/user'
import UserCard from '../ui/DS/userCard'
import ProfilPicture from '../ui/DS/profilPicture'
import Button from '../ui/DS/button'

type userCard = User & { ctaCallback?: (user: User) => void, disabledCta?: boolean, ctaLabel: string }


const ResultCard: React.FC<userCard> = ({ _id, firstname, lastname, username, roles, avatar, disabledCta=false, ctaLabel, ctaCallback }) => {
    const user = {
        _id,
        username,
        firstname,
        lastname,
        roles,
        avatar
    }
    return (
        <UserCard>
            <>
                <div>
                    <ProfilPicture avatar={avatar} width="60px" height="60px" />
                </div>
                <div>
                    <p>{username}</p>
                    <p><i>{`${firstname} ${lastname}`}</i></p>
                </div>
                <div>
                    {!disabledCta && <Button styleType="primary"  onClick={() => ctaCallback && ctaCallback(user)}>{ctaLabel}</Button>}
                </div>
            </>
        </UserCard>
    )
}

export default ResultCard