import { UpdatePassword } from "../../shared/models/user"


export default function validate(values: UpdatePassword) {
  let errors = {} as any
  if (!values.oldPassword) {
    errors.oldPassword = "L'ancien mot de passe est requise"
  }
  if (!values.newPassword) {
    errors.newPassword = "Le nouveau mot de passe est requis"
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&#^]{8,})/.test(values.newPassword)) {
    errors.newPassword = "Le nouveau password doit contenir au minimum 8 caractères avec au minimun 1 majuscule, 1 minuscule et un caractère spéciale"
  } else if (values.oldPassword === values.newPassword) {
    errors.newPassword = "Le nouveau mot de passe doit être différent de l'ancien"
  }
  return errors
}