import { useEffect, useState } from 'react'

const useRecipeFilters = <T>(defaultFilters: T, callback:(values: any) => void) => {
    const [values, setValues] = useState(defaultFilters)
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isSubmitting) {
          console.log(values)
          callback(values)
          setIsSubmitting(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isSubmitting, callback, defaultFilters]);
    
    const handleChange = (event: any, meta: any) => {
        setValues({ ...values, [meta.name]: event.value })
    }

    const handleSubmit = (event: any) => {
        localStorage.setItem("recipeFilters", JSON.stringify(values))
        setIsSubmitting(true);
    }

    return { 
        values,
        setValues,
        handleChange,
        handleSubmit
    }
}

export default useRecipeFilters