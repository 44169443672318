import * as React from "react";

const GotToIcon = ({
    width = "35",
    height = "35",
    color = "white",
    className

}: React.SVGProps<SVGSVGElement>) => (
    <svg className={className} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.498 34.2851C26.7145 34.2851 34.186 26.8136 34.186 17.5971C34.186 8.38053 26.7145 0.909058 17.498 0.909058C8.28147 0.909058 0.809998 8.38053 0.809998 17.5971C0.809998 26.8136 8.28147 34.2851 17.498 34.2851Z" stroke="white" strokeWidth="1.2516" strokeMiterlimit="10" />
        <path d="M24.725 16.125L17.498 23.354L10.269 16.125" stroke={color} strokeWidth="1.2516" strokeMiterlimit="10" />
    </svg>
)

export default GotToIcon