import facepaint from 'facepaint'

const size = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
}


export const mq = facepaint([
    `@media(min-width: ${size.sm})`,
    `@media(min-width: ${size.md})`,
    `@media(min-width: ${size.lg})`,
    `@media(min-width: ${size.xl})`,
    `@media(min-width: ${size.xxl})`,
]);