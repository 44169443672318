import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
    return (
        <Wrapper>
            <Item><span style={{cursor: "pointer"}} onClick={() => (window as any).openAxeptioCookies()}>Gestion des cookies</span></Item>
            <Item><Link to="/mentions-legales">Mentions légales</Link></Item>
        </Wrapper>
        
    )
}

const Wrapper = styled.div(({theme}) => ({
    position: "absolute",
    width: "100%",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.colors.grey.light,
    padding: theme.spacer.s
}))

const Item = styled.div(({theme}) => ({
    marginRight: theme.spacer.s,
    fontSize: theme.text.size.s
}))

export default Footer