import React, { useRef } from 'react'
import HomeHero from '../components/home/hero'
import Howtowork from '../components/home/howtowork'
import homeData from "../shared/datas/home.json"

const Home: React.FC = () => {
    const data = homeData as any
    const howToRef = useRef<HTMLElement>(null)
    const scrollTo = () => {
        const pos = howToRef.current?.getBoundingClientRect().top
        if(pos)
            window.scrollTo(0, pos)
    }
    return (
        <div>
            <section>
                <HomeHero scrollTo={scrollTo} data={data.hero} />
            </section>
            <section ref={howToRef}>
                <Howtowork data={data.howtowork} />
            </section>
        </div>
    )

}

export default Home