import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Theme } from '../../../theme'

type Types = "primary" | "secondary" | "tertiary"
type Sizes = "xs" | "s" | "m"

interface Button {
    type: Types
    size?: Sizes
    onClick: (event: any) => void
    children: React.ReactChild
    className?: string
}

const OutlineButton: React.FC<Button> = ({ children, onClick, type, className, size }) => {
    const [isClicking, setIsClicking] = useState(false)

    const callback = (e: any) => {
        setIsClicking(true)
        onClick(e)
    }

    useEffect(() => {
        if (isClicking)
            setTimeout(() => setIsClicking(false), 200)
    }, [isClicking])


    return (
        <ButtonStyled onClick={callback} className={className} type={type} size={size} isClicking={isClicking}>{children}</ButtonStyled>
    )
}

const baseButtonStyle = styled.div(({theme}) => ({
    padding: "0.5rem 1rem",
    borderRadius: theme.radius.regular,
    transition: "all 0.2s ease-in ",
    textDecoration: "none",
    display: "inline-block",
    color: theme.colors.secondary.regular,
    cursor: "pointer",
    lineHeight: "normal"
}))

const getSize = (type: Sizes | undefined, theme: Theme): string => {
    switch (type) {
        case "xs": return theme.text.size.xs
        case "s": return theme.text.size.s
        case "m": return theme.text.size.m
        default: return theme.text.size.m
    }
}

const getColor = (type: Types, theme: Theme, alternativeState?: boolean) => {
    switch (type) {
        case "primary": return alternativeState ? theme.colors.primary.light : theme.colors.primary.regular
        case "secondary": return alternativeState ? theme.colors.secondary.light : theme.colors.secondary.regular
        case "tertiary": return alternativeState ? theme.colors.tertiary.light : theme.colors.tertiary.regular
        default: return alternativeState ? theme.colors.primary.light : theme.colors.primary.regular
    }
}

const ButtonStyled = styled(baseButtonStyle)<{type: Types, size?: Sizes, isClicking: boolean}>(({theme, type, size, isClicking}) => ({
    backgroundColor: "white",
    color: theme.colors.secondary.regular,
    border: `2px solid ${isClicking ? getColor(type, theme, true) : getColor(type, theme)}`,
    fontSize: getSize(size, theme),
}))

export default OutlineButton