import styled from '@emotion/styled'
import React from 'react'
import IngredientQuantities from './ingredientQuantities'

interface IngredientsListProps {
    data?: any[]
    removeIngredient: (id: string) => void
    callback: (quantity: {value: number, unit?: string}, id: string) => void
}

const IngredientsList: React.FC<IngredientsListProps> = ({ data, removeIngredient, callback }) => {
    return (
        <IngredientListStyle>
            {
                (data && data.length > 0) && data.map((item) => 
                <FlexLine key={item.id}>
                    <IngredientQuantities id={item.id} quantity={item.quantity} callback={callback} />
                    <IngredientName>{item.name}</IngredientName>
                    <i onClick={() => removeIngredient(item.id)} className="bi bi-x"></i>
                </FlexLine>)

            }
        </IngredientListStyle>
    )
}

const IngredientName = styled.div(({theme}) => ({
    width: "60%"
}))

const IngredientListStyle = styled.div(({theme}) => ({
    marginBottom: theme.spacer.m
}))

const FlexLine = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacer.s
}))

export default IngredientsList