/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { theme } from '../../styles/theme'
import Button from './DS/button'

interface ConfirmationPopin {
    children: React.ReactChild
    refPopin: React.RefObject<any>
    onClose: () => void
    onConfirm: () => void
}

const ConfirmationPopin: React.FC<ConfirmationPopin> = ({ children, refPopin, onClose, onConfirm }) => {
    return (
        <Modal>
            <Popin ref={refPopin}>
                <div className='close' onClick={onClose}>close</div>
                <div>{children}</div>
                <Button type="button" css={css`margin-right: ${theme.spacer.s}`} styleType="secondary" onClick={onClose}>Annuler</Button>
                <Button type="button" styleType="primary" onClick={onConfirm}>Confirmer</Button>
            </Popin>
        </Modal>
    )
}

const Modal = styled.div(({ theme }) => ({
    left: 0,
    top: 0,
    background: "rgba(75, 73, 73, 0.6)",
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: 100,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

const Popin = styled.div(({theme}) => ({
    backgroundColor: "white",
    maxWidth: "80%",
    maxHeight: "50%",
    overflowY: "scroll",
    position: "relative",
    padding: theme.spacer.s,
    boxShadow: theme.boxShadow.light
}))

export default ConfirmationPopin