import styled from '@emotion/styled'
import React from 'react'
import { Step } from '../../shared/models/recipe'
import Textarea from '../ui/DS/textarea'

interface StepFieldProps {
    step: Step
    stepContentCallback: (step: Step) => void
}

const StepField: React.FC<StepFieldProps> = ({ step, stepContentCallback }) => {
    const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        stepContentCallback({content: e.currentTarget.value, position: step.position})
    }
    return (
        <FlexWrapper>
            <PositionBlock>{step.position}</PositionBlock>
            <TextareaStyle value={step.content} onChange={onChange} />
        </FlexWrapper>
    )
}

const PositionBlock = styled.div(({theme}) => ({
    paddingRight: "32px",
    fontSize: theme.text.size.m
}))

const FlexWrapper = styled.div(({theme}) => ({
    display: "flex",
    width: "100%",
    marginBottom: theme.spacer.s
}))

const TextareaStyle = styled(Textarea)(() => ({
    flexGrow: 1,
    minHeight: "100px"
}))

export default StepField