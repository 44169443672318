import styled from '@emotion/styled'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SubscribtionNav: React.FC = () => {
    const location = useLocation()
    const url = {
        mySubscriptions : "/amis/mesabonnements",
        mySubscribers : "/amis/mesabonnes"

    }
    const isActive = (url: string) => {
        return url === location.pathname  
    }
    return (
        <Nav>
            <NavItem isActive={isActive(url.mySubscriptions)}><Link to={url.mySubscriptions}>Mes abonnements</Link></NavItem>
            <NavItem isActive={isActive(url.mySubscribers)}><Link to={url.mySubscribers}>Mes abonnés</Link></NavItem>
        </Nav>
    )
}

const Nav = styled.nav(({theme}) => ({
    backgroundColor: theme.colors.secondary.light,
    marginBottom: theme.spacer.l,
    display: "flex",
    border: `1px solid ${theme.colors.secondary.light}`
}))

const NavItem = styled.div<{isActive: boolean}>(({theme, isActive}) => ({
    borderRight: `1px solid ${theme.colors.secondary.light}`,
    backgroundColor: isActive ? theme.colors.secondary.regular : "white",
    textAlign: "center",
    width: "50%",
    "& :last-child": {
        border: 0
    },
    "& a": {
        display: "block",
        padding: theme.spacer.xs,
        color: isActive ? "white" : theme.colors.secondary.regular
    }
}))

export default SubscribtionNav