import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router'
import { useAuthContext } from '../shared/contexts/authContext'
import { getCookie } from '../shared/utils/utils'


interface PrivateRouteProps {
    path: string,
    exact?: boolean,
    component: React.FC
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = (props) => {
    const { dispatch } = useAuthContext()
    const history = useHistory()
    const { path, exact, component } = props
    const isAuth = getCookie('auth')
    useEffect(() => {
        if(!isAuth) {
            dispatch({
                type: "LOGOUT_AUTH"
            })
            history.push({pathname: "/connexion", state: {from: path}})
        }
    }, [isAuth, dispatch])    
    return (
        (isAuth) ?
            <Route
                path={path}
                exact={exact}
                component={component}
            />
            : null
    )
}

export default PrivateRoute