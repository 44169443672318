import React, {
  createContext,
  useContext,
  useReducer
} from 'react'

type Avatar = {
  url: string
  key: string
}

type InitialAuthState = {
  id?: string,
  isLogged?: boolean,
  username?: string,
  firstname?: string,
  lastname?: string,
  email?: string,
  roles: any[],
  avatar?: Avatar
}
type AuthState = {
  state: InitialAuthState,
  dispatch: React.Dispatch<AuthActionTypes>
}

export type AuthActionTypes = ActionSet | ActionLogout

type ActionSet = {
  type: "SET_AUTH"
  payload: InitialAuthState
}

type ActionLogout = {
  type: "LOGOUT_AUTH"
}

export const initialAuthState = {
    id: undefined,
    isLogged: undefined,
    username: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    roles: [],
    avatar: undefined
}

export const AuthContext = createContext<AuthState>({
  state: initialAuthState,
  dispatch: () => null
})

const authReducer = (state: InitialAuthState, action: AuthActionTypes) => {
  switch (action.type) {
    case 'SET_AUTH':
      return action.payload
    case 'LOGOUT_AUTH':
      return initialAuthState
    default:
      return state
  }
}

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState)

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
        <>{children}</>
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContext.Provider')
  }
  return context
}
