import React, { useEffect } from 'react'
import './index.css'
import UnLoggedApp from './unloggedApp'
import LoggedApp from './loggedApp';
import { useAuthContext } from './shared/contexts/authContext'
import useFetch from './shared/hooks/useFetch'
import { getCookie } from './shared/utils/utils'
import { User } from './shared/models/user'
import { Global, css, ThemeProvider } from "@emotion/react"
import emotionNormalize from 'emotion-normalize'
import { theme } from './styles/theme'
import lato from './styles/fonts/lato.ttf'
import latoBold from './styles/fonts/Lato-Bold.ttf'
import oswald from './styles/fonts/oswald.ttf'
import { getCsrfToken } from './shared/utils/utils'
import { UiProvider } from './shared/contexts/uiContext';


const App: React.FC = () => {
  const { state, dispatch } = useAuthContext()
  const isAuthCookie = getCookie('auth')
  const { data, error } = useFetch<User>(isAuthCookie ? "/api/user" : "")

  // 
  if (window.location.protocol === "http:" && process.env.NODE_ENV === "production")
    window.location.href = `https://${window.location.hostname}${window.location.pathname}`

  useEffect(() => {
    getCsrfToken()
  }, [])

  useEffect(() => {
    if (data && !error) {
      dispatch({
        type: "SET_AUTH",
        payload: data
      })
    }
  }, [data])

  return (
    <>
      <Global
        styles={css`
          ${emotionNormalize}
          @font-face {
            font-family: 'lato';
            src: url(${lato}) format('truetype');
            font-weight: 300;
            font-style: normal;
          }
          @font-face {
            font-family: 'latoBold';
            src: url(${latoBold}) format('truetype');
            font-weight: 300;
            font-style: normal;
          }
          @font-face {
            font-family: 'oswald';
            src: url(${oswald}) format('truetype');
            font-weight: 300;
            font-style: normal;
          }
          ul, li {
            list-style-type: none;
            margin: 0;
            padding: 0;
          }
          a {
            text-decoration: none;
            color: ${theme.colors.secondary.regular}
          }
          a: hover, a: visited {
            color: ${theme.colors.secondary.regular}
          }
          html,
          body {
            padding: 0;
            margin: 0;
            background: white;
            font-family: 'lato', Arial, sans-serif;
          }
          h1, h2, H3, h4, h5, h6 {
            margin: 0
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <UiProvider>
          {state.isLogged === true && isAuthCookie &&
            <LoggedApp />
          }
          {!isAuthCookie &&

            <UnLoggedApp />

          }
        </UiProvider>
      </ThemeProvider>
    </>
  )
}
export default App