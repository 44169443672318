import styled from '@emotion/styled'
import React from 'react'
import ChangePassword from '../components/myaccount/changePassword'
import ProfilPictureEditor from '../components/myaccount/profilPictureEditor'
import { useAuthContext } from '../shared/contexts/authContext'
import { Avatar } from '../shared/models/user'
import { H1, H2} from '../styles'
import { theme } from '../styles/theme'
import { deleteAvatar } from '../shared/api/routes'
import Toaster from '../components/ui/toaster'
import useToaster from '../shared/hooks/useToaster'
import CloseIcon from '../styles/svg/CloseIcon'

const MyAccount: React.FC = () => {
    const { state: userData, dispatch } = useAuthContext()
    const { renderToaster, enableTranstion, dispatch: toasterDispatch } = useToaster()
    
    const onToasterIsMounted = () => {
        toasterDispatch({ type: "enableTransition", payload: true })
    }
    const onToasterClose = () => {
        toasterDispatch({ type: "renderToaster", payload: undefined })
    }
    const onSaveImageProfil = (avatar?: Avatar, msg?: string) => {
        dispatch({ type: "SET_AUTH", "payload": { ...userData, avatar } })
        if(msg)
            toasterDispatch({ type: "renderToaster", payload: {status: "success", msg }})
    }

    const onErrorImageProfil = (msg: string) => {
        toasterDispatch({ type: "renderToaster", payload: {status: "error", msg }})
    }

    const onDelete = async () => {
        try {
            const response = await deleteAvatar()
            if (response.ok) {
                const json = await response.json()
                dispatch({ type: "SET_AUTH", "payload": { ...userData, avatar: undefined } })
                toasterDispatch({ type: "renderToaster", payload: {status: "success", msg: json }})
            } else {
                if (response.status === 401 || response.status === 403) {
                    window.location.href = "/connexion"
                }
                toasterDispatch({ type: "renderToaster", payload: {status: "error", msg: "Erreur lors de la sauvegarde de l'image de profil" }})
            }
        } catch (e) {
            console.log(e)
            toasterDispatch({ type: "renderToaster", payload: {status: "error", msg: "Erreur lors de la sauvegarde de l'image de profil" }})
        }
    }

    return (
        <div>
            <H1>Mon compte</H1>
            <SectionStyle>
                <H2>Informations personnelles</H2>
                {
                    userData.firstname &&
                    <div style={{ marginBottom: theme.spacer.xs }}>Pseudo : {userData.username}</div>
                }
                {
                    userData.firstname &&
                    <div style={{ marginBottom: theme.spacer.xs }}>Prénom : {userData.firstname}</div>
                }
                {
                    userData.lastname &&
                    <div style={{ marginBottom: theme.spacer.xs }}>Nom : {userData.lastname}</div>
                }
            </SectionStyle>
            <SectionStyle>
                <H2>Image de profil</H2>
                {
                    userData.avatar &&
                    <AvatarWrapper>
                        <img src={userData.avatar.url} width="250" height="250" alt={userData.username} />
                        <DeleteBtn onClick={onDelete}><CloseIcon /></DeleteBtn>
                    </AvatarWrapper>
                }
                <ProfilPictureEditor uploadBtnLabel={userData.avatar ? "Changer mon image de profil..." : "Ajouter une image de profil..."} onSave={onSaveImageProfil} onError={onErrorImageProfil} />
            </SectionStyle>
            <SectionStyle>
                <H2>Changer de mot de passe</H2>
                <ChangePassword />
            </SectionStyle>
            {renderToaster &&
                <Toaster 
                    enableTransition={enableTranstion} 
                    status={renderToaster.status} 
                    msg={renderToaster.msg}
                    autoClose={false}
                    onClose={onToasterClose} 
                    onMount={onToasterIsMounted} />
            }
        </div>
    )

}



const SectionStyle = styled.section(({ theme }) => (
    {
        marginBottom: theme.spacer.m
    }
))

const DeleteBtn = styled.div((display) => (
    {
        cursor: "pointer",
        position: "absolute",
        right: 0,
        top: 0,
        padding: "1rem"
    }
))

const AvatarWrapper = styled.div(({ theme }) => ({
    width: "250px",
    height: "250px",
    position: 'relative',
    marginBottom: theme.spacer.m
}))


export default MyAccount