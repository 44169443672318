export function validateEmail(values: any) {
  let errors = {} as any

  if (!values.email) {
    errors.email = "L'adresse e-mail est requise"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "L'adresse e-mail est invalide"
  }
  return errors
}

export function validatePassword(values: any) {
  let errors = {} as any

  if (!values.password) {
    errors.password = 'Merci de saisir un password'
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&#^]{8,})/.test(values.password)) {
    errors.password = 'Le password doit contenir au minimum 8 caractères avec au minimun 1 majuscule, 1 minuscule et un caractère spéciale'
  }
  return errors
}