import { Recipe } from "../../shared/models/recipe";

export default function validate(values: Recipe) {
    let errors = {} as any;
    if (!values.name) {
      errors.name = 'Merci de saisir un nom';
    }
    if (values.ingredients.length === 0) {
      errors.ingredients = 'Merci de sélectionner au moins un ingrédient';
    }
    if (values.steps.length > 0 && !values.steps[0].content) {
      errors.steps = 'Merci de saisir au moins une étape';
    }
    return errors;
  }