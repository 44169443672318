import React from 'react'
import ButtonLink from '../components/ui/DS/buttonLink'
import InfoBlock from '../components/ui/DS/infoBlock'
import LoadingInline from '../components/ui/DS/loadingInline'
import useFetch from '../shared/hooks/useFetch'

const AccountValidation: React.FunctionComponent = (props: any) => {
    const hash = props.match.params.hash
    const userSponsorId = props.match.params.userSponsorId

    const url = `${process.env.REACT_APP_API_HOST}/account/${hash}/follow/${userSponsorId}`
    const { loading, data, error } = useFetch<string>(url)

    return (
        <div>
            {error && <InfoBlock><div>{error}</div></InfoBlock>}
            {loading && <LoadingInline />}
            {data && 
                <div>
                    <div>{data}</div>
                    <ButtonLink type="primary" to="/connexion">Se connecter</ButtonLink>
                </div>
            }
        </div>
        
    )
}
export default AccountValidation

