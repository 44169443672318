/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react'
import useFetch from '../shared/hooks/useFetch'
import { authJsonHeader } from '../shared/utils/utils'
import { RecipeWithAuthor } from '../shared/models/recipe'
import { useHistory } from 'react-router-dom'
import {
    useParams
} from 'react-router-dom'
import ConfirmationPopin from '../components/ui/confirmationPopin'
import useConfirmationPopin from '../shared/hooks/useConfirmationPopin'
import { useAuthContext } from '../shared/contexts/authContext'
import { useTheme, css, jsx } from '@emotion/react'
import { H1, H3 } from '../styles'
import TextBold from '../components/ui/DS/textBold'
import ButtonLink from '../components/ui/DS/buttonLink'
import Button from '../components/ui/DS/button'
import styled from '@emotion/styled'
import RecipeTimeIcon from '../styles/svg/recipeTimeIcon'
import CookingTimeIcon from '../styles/svg/cookingTimeIcon'
import { S3_BASE_URL } from "../shared/config"
import { mq } from '../styles/devices'

const Recipe: React.FunctionComponent = () => {
    const theme = useTheme()
    let history = useHistory()
    const { state } = useAuthContext()
    interface Params {
        id: string
    }
    const { id } = useParams<Params>()
    const deleteOptions = {
        method: "DELETE",
        headers: {
            'X-CSRF-Token': localStorage.getItem('csrfToken')
        }
    }
    // state
    const [deleteUrl, setDeleteUrl] = useState("")
    // api
    const url = `${process.env.REACT_APP_API_HOST}/recipe/${id}`
    const { data: recipe, error, loading } = useFetch<RecipeWithAuthor>(url, authJsonHeader)
    const { data: deleteRecipe } = useFetch<any>(deleteUrl, deleteOptions)
    
    const refPopin = useRef<React.RefObject<any>>(null)
    const { popinIsOpen, handleClickPopin } = useConfirmationPopin(false, refPopin)

    const handleDelete = () => {
        handleClickPopin()
    }

    const confirmDelete = () => {
        setDeleteUrl(`${process.env.REACT_APP_API_HOST}/recipe/${id}`)
    }

    const closeModal = () => {
        handleClickPopin()
    }

    useEffect(() => {
        if (deleteRecipe) {
            history.push("/recettes")
        }
    }, [deleteRecipe])

    return (
        <div>
            {popinIsOpen && 
                <ConfirmationPopin onConfirm={confirmDelete} onClose={closeModal} refPopin={refPopin}>
                    <p>Etes-vous sûr de vouloir supprimer cette recette ?</p>
                </ConfirmationPopin>
            }
                    
            {error && <p>{error}</p>}
            {loading && <p>Loading...</p>}
            {recipe &&
                <div>
                    <H1>{recipe.name}</H1>
                    <p><TextBold><span>Mijoté par : {recipe.author.username}</span></TextBold></p>
                    <FlexWrapperCenter className="mb-4">
                        <FlexWrapperCenter><RecipeTimeIcon color={theme.colors.secondary.regular} /><span>{recipe.recipeTime} min</span></FlexWrapperCenter>
                        <FlexWrapperCenter><CookingTimeIcon color={theme.colors.secondary.regular} /><span>{recipe.cookingTime} min</span></FlexWrapperCenter>
                    </FlexWrapperCenter>
                    <RecipeContent>
                        <ImgBlock>
                            {recipe.files.length > 0 && recipe.files[0].large && <img width="100%" src={`${S3_BASE_URL}/${recipe.files[0].large}`} alt={recipe.name} />}
                        </ImgBlock>
                        <ContentBlock>
                            <H3>Liste des ingrédients</H3>
                            <ul>
                                {recipe.ingredients.map(ingredient =>
                                    <li key={ingredient.id}>
                                        {ingredient.quantity.value !== 0 && ingredient.quantity.value} {ingredient.quantity.unit !== "-" && ingredient.quantity.unit} {ingredient.name}
                                    </li>)}
                            </ul>
                        </ContentBlock>
                    </RecipeContent>
                    <div className='mb-4'>
                        <h3>Etapes de la recette</h3>
                        {
                            recipe.steps.map(step => <div className='mb-4 bg-light p-4' key={step.position}>
                                {step.position}- {step.content}
                            </div>)
                        }
                    </div>
                    {
                        recipe.author._id === state.id &&
                        <div>
                            <ButtonLink css={css`margin-right: ${theme.spacer.s}`} type="primary" to={`/recettes/edition/${recipe._id}`}>Modifier</ButtonLink>
                            <Button type="button" styleType="alert" onClick={handleDelete}>Supprimer</Button>
                        </div>
                    }
                </div>
            }
        </div>
    )
}



const RecipeContent = styled.div(({ theme }) => ({
    display: "flex",
    width: "100%",
    flexFlow: "wrap",
    marginBottom: theme.spacer.xl
}))

const ImgBlock = styled.div(
    mq({
        marginBottom: ["1rem"],
        width: ["100%", "25%"],
    })
)

const ContentBlock = styled.div(
    mq({
        width: ["100%", "75%"],
        paddingLeft: [0, "10px"]
    })
)

const FlexWrapperCenter = styled.div({
    display: "flex",
    alignItems: "center",
    marginRight: "10px"
})

export default Recipe