import React from 'react'
import { useTheme, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import Loading from '../../../styles/svg/loading'


interface LoadingInline {
    className?: string
}

const LoadingInline: React.FC<LoadingInline> = ({ className }) => {
    const theme = useTheme()

    const LoadingInlineStyled = styled('div')({
        marginTop: theme.spacer.m,
        fontSize: theme.text.size.m
    })

    const anim = keyframes` 
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
    `

    const LoadingStyle = styled(Loading)({
        animation: `${anim} 1s linear infinite`,
        width: "20px"
    })


    return (
        <LoadingInlineStyled className={className}>Loading <LoadingStyle /></LoadingInlineStyled>
    )
}

export default LoadingInline