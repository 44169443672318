/** @jsx jsx */
import React, { useEffect, useState, } from 'react'

import useFetch from '../shared/hooks/useFetch'
import { authJsonHeader } from '../shared/utils/utils'

import useForm from '../shared/hooks/useForm'
import { useAuthContext } from '../shared/contexts/authContext'
import SubscribtionNav from '../components/subscriptions/subscriptionNav'
import SearchUser from '../components/subscriptions/searchUser'
import { H2 } from '../styles'
import InputField from '../components/forms/InputField'
import LoadingInline from '../components/ui/DS/loadingInline'
import Button from '../components/ui/DS/button'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { theme } from '../styles/theme'
import InfoBlock from '../components/ui/DS/infoBlock'
import { follow, unfollow } from '../shared/api/routes'
import useToaster from '../shared/hooks/useToaster'
import Toaster from '../components/ui/toaster'
import { User } from '../shared/models/user'
import ResultCard from '../components/subscriptions/resultCard'

type UserFollowings = {
    waitingFollowings: User[]
    followings: User[]
}

const MySubscriptions: React.FC = () => {
    const { state } = useAuthContext()
    const { enableTranstion, renderToaster, dispatch: toasterDispatch } = useToaster()
    const { id: userId, firstname, lastname } = state
    const [followingsState, setFollowingsState] = useState<undefined | UserFollowings>(undefined)
    const [clearSearch, setClearSearch] = useState(false)
    const defaultFetchParams = {
        url: "",
        options: {}
    }

    const { data: followings } = useFetch<UserFollowings>(`${process.env.REACT_APP_API_HOST}/user/${userId}/followings`, authJsonHeader)

    const onToasterIsMounted = () => {
        toasterDispatch({ type: "enableTransition", payload: true })
    }
    const onToasterClose = () => {
        toasterDispatch({ type: "renderToaster", payload: undefined })
    }


    // invitation 
    const [postInvitationUrl] = useState("")
    const { data: postInvitation, error: errorPostInvitation, loading: loadingInvitaion } = useFetch<any>(postInvitationUrl, authJsonHeader)

    // followings email 
    const [postEmail, setPostEmail] = useState(defaultFetchParams)
    const { data: resultPostEmail, error: errorpostEmail, loading: loadingPostEmail } = useFetch<any>(postEmail.url, postEmail.options)


    const emailCallback = () => {
        setPostEmail({
            url: `${process.env.REACT_APP_API_HOST}/subscription/email`,
            options: {
                ...authJsonHeader,
                method: "post",
                body: JSON.stringify({
                    userSponsorId: userId,
                    email: values.email,
                    firstname,
                    lastname
                })
            }
        })
        setValues(defaultEmailState)
    }

    const followCallback = async (user: User) => {
        try {
            const response = await follow({ id: userId as string, userFollowedId: user._id })
            const json = await response.json()
            if (response.ok) {
                if(followingsState)
                    setFollowingsState({ ...followingsState, waitingFollowings: [...followingsState.waitingFollowings, user]})
                toasterDispatch({ type: "renderToaster", payload: { status: "success", msg: json } })
                setClearSearch(true)
            } else {
                toasterDispatch({ type: "renderToaster", payload: { status: "error", msg: json } })
            }
        } catch (e) {
            toasterDispatch({ type: "renderToaster", payload: { status: "error", msg: "outch" } })
        }
    }

    const unfollowCallback = async (user: User) => {
        const clearFollowing = (followings: User[], id: string) => {
            return followings.filter(following => following._id !== id)
        }
        
        try {
            const response = await unfollow({ id: userId as string, userFollowedId: user._id })
            const json = await response.json()
            if (response.ok) {
                if (followingsState)
                    setFollowingsState({ ...followingsState, followings: clearFollowing(followingsState.followings, user._id)})
                toasterDispatch({ type: "renderToaster", payload: { status: "success", msg: json } })
            } else {
                toasterDispatch({ type: "renderToaster", payload: { status: "error", msg: json } })
            }
        } catch (e) {
            toasterDispatch({ type: "renderToaster", payload: { status: "error", msg: "outch" } })
        }
    }

    const validateEmail = (values: any) => {
        let errors = {} as any;
        if (!values.email) {
            errors.email = `L'adresse email est requise`;
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            errors.email = `L'adresse email n'est pas valide`;
        }
        return errors
    }


    const defaultEmailState = {
        email: ""
    }
    const { values, setValues, errors, handleChange: handleChangeEmail, handleKeyup: handleBlurEmail, handleSubmit: handleSubmitEmail } = useForm<typeof defaultEmailState>(
        defaultEmailState,
        emailCallback,
        validateEmail,
    )

    useEffect(() => {
        if (followings) {
            setFollowingsState(followings)
        }
    }, [followings])

    return (
        <div>
            <SubscribtionNav />
            <div className='mb-4'>
                <H2>Mes demandes d'abonnements en cours</H2>
                {
                    followingsState && followingsState.waitingFollowings.length > 0 ?
                        followingsState.waitingFollowings.map((following, index) =>
                            <ResultCard disabledCta={true} ctaLabel={"demande en attente"} key={index} {...following} />
                        )
                        : <div>Pas de demande d'abonnement</div>

                }
            </div>
            <div className='mb-4'>
                <H2>Mes abonnements</H2>
                {
                    followingsState && followingsState.followings.length > 0 ?
                        followingsState.followings.map((following, index) =>
                            <ResultCard ctaCallback={unfollowCallback} ctaLabel={"me désabonner"} key={index} {...following} />
                        )
                        : <div>Pas encore d'abonnement</div>

                }
            </div>
            <div className='mb-4'>
                <H2>Rechercher un utilisateur pour suivre ses recettes</H2>
                <SearchUser clear={clearSearch} subscriptionCallback={followCallback} />
                {loadingPostEmail && <LoadingInline />}
                {resultPostEmail && <div>{resultPostEmail}</div>}
                {errorpostEmail && <InfoBlock css={css`margin-top: ${theme.spacer.m}`}><div>{errorpostEmail}</div></InfoBlock>}
            </div>

            <div className='mb-4'>
                <H2>Je ne trouve pas d'utilisateur à suivre, je l'invite par email</H2>
                <FlexLine>
                    <InputField type="text" id="email" name="email" value={values.email} onChange={handleChangeEmail} onKeyUp={handleBlurEmail} />
                    <Button type="button" css={css`margin-left: ${theme.spacer.xs}`} styleType="primary" onClick={handleSubmitEmail}>Inviter</Button>
                </FlexLine>
                {(errors as any).email && <Error>{(errors as any).email}</Error>}
            </div>

            {errorPostInvitation && <p>{errorPostInvitation}</p>}
            {loadingInvitaion && <LoadingInline />}
            {postInvitation && <div>Invitation envoyé</div>}

            {renderToaster &&
                <Toaster
                    enableTransition={enableTranstion}
                    status={renderToaster.status}
                    msg={renderToaster.msg}
                    autoClose={false}
                    onClose={onToasterClose}
                    onMount={onToasterIsMounted} />
            }
        </div>
    )

}


const Error = styled.p(({ theme }) => ({
    color: theme.colors.alert.regular,
}))

const FlexLine = styled.div(({
    display: "flex",
    alignItems: "center"
}))

export default MySubscriptions