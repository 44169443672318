import React from 'react'

import styled from '@emotion/styled'
import InputField, { InputFieldProps } from '../../forms/InputField'


interface InputSearchProps {
    onClear: () => void
}

const InputSearch: React.FC<InputSearchProps & InputFieldProps> = ({ id, type, name, value, onChange, onClear }) => {

    return (
        <InputSearchStyle>
            <SearchWrapper>
                <InputFieldStyle id={id} type={type} name={name} onChange={onChange} value={value} />
                {value !== "" && <ClearCursor onClick={onClear}>x</ClearCursor>}
            </SearchWrapper>
            <span className="btn btn-secondary">
                <i className="bi bi-search"></i>
            </span>
        </InputSearchStyle>
    )
}
const ClearCursor = styled.span({
    position: "absolute",
    right: "7px",
    top: "6px",
    cursor: "pointer"
})

const InputSearchStyle = styled.div(() => ({
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem"
}))

const InputFieldStyle = styled(InputField)(({theme}) => ({
    "& input" : {
        paddingRight: "10px",
        borderRadius: `${theme.radius.light} 0 0 ${theme.radius.light}`
    }
    
}))

const SearchWrapper = styled.div(() => ({
    position: "relative",
}))

export default InputSearch