import { useState, useEffect } from 'react';

const useForm = <T>(defaultValue: T, callback:() => void, validate:(values: any) => any) => {

  const [values, setValues] = useState(defaultValue)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLive, setIsLive] = useState(false)

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && !isLive) {
      callback()
      setIsSubmitting(false)
      setValues(defaultValue)
    }
  }, [errors, isLive, isSubmitting, callback, defaultValue])

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault()
    setErrors(validate(values))
    setIsSubmitting(true)
    setIsLive(false)
  };

  const handleChange = (event: any) => {

    const getValue = (event: any) => {
      switch (event.target.type) {
        case 'checkbox':
          return event.target.checked
        case 'file':
          return event.target.files
        default:
          return event.target.value
      }
    }
    setValues({ ...values, [event.target.name]: getValue(event) })
  }

  const handleKeyup = () => {
    if (isSubmitting) {
      setIsLive(true)
      setErrors(validate(values));
    }
      
  }

  return {
    handleChange,
    handleKeyup,
    handleSubmit,
    setValues,
    values,
    errors,
  }
}

export default useForm