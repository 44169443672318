import styled from '@emotion/styled'
import React from 'react'
import InputField from '../components/forms/InputField'
import { validateEmail, validatePassword } from '../components/forms/forgotPasswordValidationRules'
import Button from '../components/ui/DS/button'
import { forgotPassword, forgotPasswordValidation } from '../shared/api/routes'
import useForm from '../shared/hooks/useForm'
import { H1 } from '../styles'
import { useParams } from 'react-router-dom'
import useToaster from '../shared/hooks/useToaster'
import Toaster from '../components/ui/toaster'
import CenterPageWrapper from '../components/ui/centerPageWrapper'


const ForgotPassword: React.FunctionComponent = () => {

    const params = useParams() as any


    const PasswordForm = () => {
        const defaultValue = {
            password: '',
        }

        const onCloseToaster = () => {
            dispatch({ type: "renderToaster", payload: undefined })
        }

        const onMountToaster = () => {
            dispatch({ type: "enableTransition", payload: true })
        }

        const validFormCallback = async () => {
            try {
                const response = await forgotPasswordValidation(
                    {
                        password: values.password,
                        hash: params.hash
                    }
                )
                const json = await response.json()
                if (response.ok) {
                    dispatch({
                        type: "renderToaster", payload: {
                            status: "success",
                            msg: json
                        }
                    })
                } else {
                    dispatch({
                        type: "renderToaster", payload: {
                            status: "error",
                            msg: json
                        }
                    })
                }
            } catch (err) {
                dispatch({
                    type: "renderToaster", payload: {
                        status: "success",
                        msg: "Une erreur technique est apparue"
                    }
                })
            }

        }

        const { values, errors, handleChange, handleKeyup, handleSubmit } = useForm(
            defaultValue,
            validFormCallback,
            validatePassword,
        )

        const { renderToaster, enableTranstion, dispatch } = useToaster()


        return (
            <div>
                <InputFieldStyle
                    id="password"
                    label="Nouveau pasword"
                    type="password"
                    name="password"
                    value={values.password}
                    onKeyUp={handleKeyup}
                    onChange={handleChange}
                    error={(errors as any).password}
                />
                <Button styleType="primary" onClick={handleSubmit}>Envoyer</Button>
                {
                    renderToaster &&
                    <Toaster status={renderToaster.status} msg={renderToaster.msg} enableTransition={enableTranstion} onClose={onCloseToaster} onMount={onMountToaster} autoClose={true} />
                }
            </div>
        )
    }

    const EmailForm = () => {
        const defaultValue = {
            email: '',
        }

        const onCloseToaster = () => {
            dispatch({ type: "renderToaster", payload: undefined })
        }

        const onMountToaster = () => {
            dispatch({ type: "enableTransition", payload: true })
        }

        const validFormCallback = async () => {
            try {
                const response = await forgotPassword(values)
                const json = await response.json()
                if (response.ok) {
                    dispatch({
                        type: "renderToaster", payload: {
                            status: "success",
                            msg: json
                        }
                    })
                } else {
                    dispatch({
                        type: "renderToaster", payload: {
                            status: "error",
                            msg: json
                        }
                    })
                }
            } catch (err) {
                dispatch({
                    type: "renderToaster", payload: {
                        status: "error",
                        msg: "Une erreur technique est apparue"
                    }
                })
            }

        }

        const { values, errors, handleChange, handleKeyup, handleSubmit } = useForm(
            defaultValue,
            validFormCallback,
            validateEmail,
        )

        const { renderToaster, enableTranstion, dispatch } = useToaster()

        return (
            <div>
                <InputFieldStyle
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                    onKeyUp={handleKeyup}
                    onChange={handleChange}
                    error={(errors as any).email}
                />
                <Button styleType="primary" onClick={handleSubmit}>Envoyer</Button>
                {
                    renderToaster &&
                    <Toaster status={renderToaster.status} msg={renderToaster.msg} enableTransition={enableTranstion} onClose={onCloseToaster} onMount={onMountToaster} autoClose={true} />
                }

            </div>
        )
    }

    return (
        <CenterPageWrapper>
            <form>
                <H1>Mot de passe oublié</H1>
                {params && params.hash ?
                    <PasswordForm />
                    :
                    <EmailForm />
                }
            </form>
        </CenterPageWrapper>
    )
}

const InputFieldStyle = styled(InputField)(({ theme }) => ({
    marginBottom: theme.spacer.m
}))

export default ForgotPassword