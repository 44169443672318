import React from 'react'
import { categoryData as options, themeData } from '../../shared/datas/recipe'
import GroupTags from '../ui/DS/groupTags'
import useRecipeFilters from '../../shared/hooks/useRecipeFilters'
import Button from '../ui/DS/button'
import styled from '@emotion/styled'
import { theme } from '../../styles/theme'
import TextBold from '../ui/DS/textBold'
import CustomSelect from '../ui/DS/select'
import CloseIcon from '../../styles/svg/CloseIcon'
import OutlineButton from '../ui/DS/outlineButton'
import { RecipesFilters } from '../../shared/models/recipe'

interface FiltersRecipeProps {
    filters: RecipesFilters,
    callbackFilter: (filters: RecipesFilters) => void
}

const FiltersRecipe: React.FC<FiltersRecipeProps> = ({ filters, callbackFilter }) => {
    const { values, setValues, handleChange, handleSubmit } = useRecipeFilters<RecipesFilters>(
        filters,
        callbackFilter
    )

    // theme actions
    const addThemesCallback = (key: string) => {
        const themeIsAlreadySelected = values.themes.includes(key)
        if (themeIsAlreadySelected) {
            const cleanThemes = values.themes.filter((item: string) => item !== key)
            setValues({ ...values, themes: cleanThemes })
        } else {
            setValues({ ...values, themes: [...values.themes, key] })
        }
    }

    const reinitFilters = () => {
        const defaultFilters = {
            category: '',
            themes: [],
            scope: filters.scope,
            page: 1
        }
        localStorage.setItem("recipeFilters", JSON.stringify(defaultFilters))
        setValues(defaultFilters)
        callbackFilter(defaultFilters)
    }
    return (
        <FilterContainer>
            {(values.category !== "" || values.themes.length > 0) &&
                <OutlineButtonStyle type='secondary' size='s' onClick={reinitFilters}>
                    <FlexLine><CloseIconeStyle /> <span>Réinistialiser les filtres</span></FlexLine>
                </OutlineButtonStyle>}
            <div className='mb-2'>
                <TextBoldStyle>Type de recette</TextBoldStyle>
                <CustomSelect
                    name="category"
                    selectedValue={values.category}
                    handleChange={handleChange}
                    options={options}
                />
            </div>
            <div className='mb-2'>
                <TextBoldStyle>Thèmes</TextBoldStyle>
                <GroupTags selectedValues={values.themes} data={themeData} selectedCallback={addThemesCallback} />
            </div>
            <Button type='button' size="s" onClick={handleSubmit} styleType="secondary">Valider</Button>
        </FilterContainer>
    )
}

const TextBoldStyle = styled(TextBold)(({ theme }) => ({
    display: "block",
    fontSize: theme.text.size.s,
    marginBottom: theme.spacer.xs
}))

const FlexLine = styled.div({
    display: "flex",
    alignItems: "center"
})

const CloseIconeStyle = styled(CloseIcon)(() => ({
    marginRight: theme.spacer.xs
}))

const OutlineButtonStyle = styled(OutlineButton)(({theme}) => ({
    marginBottom: theme.spacer.xs
}))

const FilterContainer = styled.div({
    padding: theme.spacer.m,
    borderTop: `1px solid ${theme.colors.grey.light}`,
    borderBottom: `1px solid ${theme.colors.grey.light}`,
    marginBottom: `${theme.spacer.l}`
})

export default FiltersRecipe