export default function validate(values: any) {
  let errors = {} as any

  if (!values.email) {
    errors.email = "L'adresse e-mail est requise"
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "L'adresse e-mail est invalide"
  }
  if (!values.password) {
    errors.password = "Le mot de passe est requis"
  }
  return errors
}