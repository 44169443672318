import styled from '@emotion/styled'
import React from 'react'
import { RecipeTheme } from '../../../shared/models/recipe'
import { theme } from '../../../styles/theme'
import OutlineButton from './outlineButton'

interface GroupTagsProps {
    selectedValues: string[]
    data?: RecipeTheme[]
    selectedCallback: (key: string) => void
    className?: string
}


const GroupTags = ({ data, selectedValues, selectedCallback, className }: GroupTagsProps) => {
    const handleClick = (key: string) => {
        selectedCallback(key)
    }

    return (
        <div>
            {
                (data && data.length > 0) && data.map((item) =>
                    <OutlineButtonStyle size="s" className={className} selectedValues={selectedValues} itemKey={item.key} key={item.key} type="primary" onClick={() => handleClick(item.key)}>{item.name}</OutlineButtonStyle>
                )
            }
        </div>
    )
}

type TagProps = {
    selectedValues: string[],
    itemKey: string
}

const OutlineButtonStyle = styled(OutlineButton)<TagProps>(({ selectedValues, itemKey }) => {
    return (
        {
            backgroundColor: selectedValues.includes(itemKey) ? theme.colors.primary.light : "white",
            marginRight: "5px",
            padding: theme.spacer.xs,
            marginBottom: theme.spacer.xs,
            "&: last-child": {
                marginRight: 0
            }
        }
    )

})

export default GroupTags