import * as React from "react";

const CloseIcon = ({
  width = "12px",
  height = "12px",
  className = undefined
}: React.SVGProps<SVGSVGElement> & { className?: string }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L11 1" stroke="black" strokeWidth="2"  />
    <path d="M11 11L1 1" stroke="black" strokeWidth="2"  />
  </svg>


)

export default CloseIcon
