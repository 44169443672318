import * as React from "react";

const Loading = ({
  width = "54px",
  height = "22px",
  className = undefined
}: React.SVGProps<SVGSVGElement> & {className ?: string}) => (
  <svg width={width} height={height} viewBox="0 0 54 22" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
<path d="M2.28582 10.2862C3.70007 9.90905 7.94284 10.4747 10.6771 10.8519C13.4113 11.229 21.1425 12.7376 22.3682 13.209C27.5538 8.40051 40.1564 7.89768 45.2791 8.77766C47.3534 9.13396 51.879 10.2862 52.8218 11.9833C53.5028 13.209 52.1618 16.886 49.9933 18.9603C48.5791 20.0917 44.9774 21.6379 39.6221 21.7888C32.928 21.9774 27.6481 21.0345 25.8567 19.9974C24.8033 19.3875 23.3867 17.781 22.8396 16.6032L21.8968 14.9061C20.5768 14.2775 15.9569 13.5861 9.63994 13.869L2.66295 13.9632C1.83326 13.8124 1.28013 13.5547 1.2487 13.3975C1.06013 13.0204 0.452462 10.7751 2.28582 10.2862Z" fill="#383838"/>
<path d="M21.8968 14.9061C20.5768 14.2775 15.9569 13.5861 9.63994 13.869L2.66295 13.9632C1.83326 13.8124 1.28013 13.5547 1.2487 13.3975C1.06013 13.0204 0.452462 10.7751 2.28582 10.2862C3.70007 9.90905 7.94284 10.4747 10.6771 10.8519C13.4113 11.229 21.1425 12.7376 22.3682 13.209C27.5538 8.40051 40.1564 7.89768 45.2791 8.77766C47.3534 9.13396 51.879 10.2862 52.8218 11.9833C53.5028 13.209 52.1618 16.886 49.9933 18.9603C48.5791 20.0917 44.9774 21.6379 39.6221 21.7888C32.928 21.9774 27.6481 21.0345 25.8567 19.9974C24.8033 19.3875 23.3867 17.781 22.8396 16.6032M21.8968 14.9061C22.2075 15.4498 22.4564 15.7781 22.8396 16.6032M21.8968 14.9061L22.8396 16.6032" stroke="black" strokeWidth="0.188567"/>
<path d="M10.8656 11.6117C7.18856 11.1403 2.3801 11.2346 2.3801 11.2346C4.79376 10.4049 10.8342 11.1089 13.8827 11.6117C16.3341 12.0161 20.5454 13.2146 21.8968 13.686C21.8968 13.686 14.5427 12.0831 10.8656 11.6117Z" fill="white"/>
<path d="M37.9364 21.4015C34.3472 21.4815 30.6498 21.0681 29.3452 20.8014C30.5722 20.8014 32.9743 21.1014 35.0726 21.1014C37.2187 21.1014 40.5092 21.0085 42.041 20.8014C45.0002 20.4013 46.3366 20.0346 47.1957 19.7012C48.0548 19.3678 49.6458 18.3676 50.2504 17.8008C50.6322 17.4429 51.8413 15.8671 52.3504 15.0003C50.9186 18.601 47.9594 19.6012 47.1957 20.0012C46.4321 20.4013 42.4229 21.3015 37.9364 21.4015Z" fill="white"/>
<path d="M26.5167 11.5119C25.8379 11.6627 24.1596 12.4547 23.5939 13.1147C23.5939 12.2096 26.6738 11.1033 28.2138 10.6633C35.8319 9.23023 43.2049 9.37479 45.9391 9.62622C46.8442 9.77707 47.5734 10.0034 47.6362 10.1919C37.3027 9.13594 29.2509 10.6319 26.5167 11.5119Z" fill="white" stroke="white" strokeWidth="0.188567"/>
<path d="M25.1967 16.3203C23.4619 15.7169 23.0911 14.6861 23.1225 14.2461C23.1225 15.0003 24.8196 15.7546 25.6681 16.0375C30.7972 17.4706 36.165 17.326 38.2079 17.0746C45.6751 16.622 49.0819 15.5032 49.899 14.9061C50.8419 14.4346 51.879 13.3032 51.9733 12.9261C51.9733 12.2661 52.0985 12.1718 51.219 11.229C51.9733 11.6061 52.3818 12.7061 52.3504 12.9261C52.2426 13.6804 51.1561 14.5918 50.2762 15.0946C47.4099 16.8294 40.2192 17.5146 36.9822 17.6403C35.4736 17.8666 31.6394 17.546 29.9109 17.3574C28.7795 17.282 26.2967 16.6346 25.1967 16.3203Z" fill="white" stroke="white" strokeWidth="0.188567"/>
</svg>


)

export default Loading
