import React from 'react'
import ProfilIcon from '../../../styles/svg/profilIcon'
import styled from '@emotion/styled'
import { Avatar } from '../../../shared/models/user'

interface ProfilPicture {
    width?: string
    height?: string
    avatar?: Avatar
}

const ProfilPicture: React.FC<ProfilPicture> = ({ width="50px", height="50px", avatar }) => {
    return (
        avatar ? 
            <ProfilImgWrapper width={width} height={height}><img src={avatar.url} width="100%" height="100%" title={avatar.key} /></ProfilImgWrapper> 
        : 
            <ProfilIcon width={width} height={height} />
    )
}

export default ProfilPicture

const ProfilImgWrapper = styled.div<Partial<ProfilPicture>>(({width, height}) => ({
    borderRadius: width,
    display: "inline-block",
    width,
    height,
    overflow: "hidden"
}))