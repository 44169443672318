import React, { useEffect, useState, useRef, useCallback } from 'react'
import FiltersRecipe from '../components/recipes/filterRecipe'
import RecipeCard from '../components/recipes/recipeCard'
import { RecipesFilters, RecipeWithAuthor } from '../shared/models/recipe'
import { useAuthContext } from '../shared/contexts/authContext'
import { recipeScopeViewData } from '../shared/datas/recipe'
import { getRecipes } from '../shared/api/routes'
import styled from '@emotion/styled'
import OutlineButton from '../components/ui/DS/outlineButton'
import FilterIcon from '../styles/svg/filterIcon'
import TabsGroup from '../components/ui/DS/tabsGroup'


const Recipes = () => {

    const recipeFiltersStored = localStorage.getItem('recipeFilters')
    const { state } = useAuthContext()
    // const loader = useRef(null)
    const { id: userId } = state
    const [page, setPage] = useState(1)

    // states
    const defaultFilterState = {
        category: "",
        themes: [],
        scope: "all"
    }

    interface DataState<T> {
        data: T
        error?: string,
        loading: boolean
    }

    const defaultDataState = {
        data: undefined,
        error: undefined,
        loading: false
    }

    const [recipesState, setRecipesState] = useState<DataState<RecipeWithAuthor[] | undefined>>(defaultDataState)
    const [filtersAreOpened, setFiltersAreOpened] = useState(false)
    const [filters, setFilters] = useState<RecipesFilters>(recipeFiltersStored ? JSON.parse(recipeFiltersStored) : defaultFilterState)
    
    // get initial recipes data and filters
    useEffect(() => {
        const getRecipesData = async () => {
            setRecipesState({
                ...recipesState,
                loading: true
            })
            try {
                const recipesResponse = await getRecipes<RecipeWithAuthor[]>(userId as string, filters, page)
                recipesResponse.parsedBody && setRecipesState({
                    ...recipesState,
                    data: recipesResponse.parsedBody,
                    loading: false
                })
            } catch (err) {
                console.log(err)
                setRecipesState({
                    ...recipesState,
                    error: "Désolé, une erreur technique est survenue",
                    loading: false
                })
            }
        }
        getRecipesData()
    }, [filters])

    // const handleObserver = useCallback(entries => {
    //     const target = entries[0]
    //     if (target.isIntersecting) {
    //         setPage((page) => page + 1)
    //     }
    //   }, [])
    
    //   useEffect(() => {
    //     const option = {
    //       root: null,
    //       rootMargin: "20px",
    //       threshold: 0
    //     };
    //     const observer = new IntersectionObserver(handleObserver, option)
    //     if (loader.current) observer.observe(loader.current)
    //   }, [handleObserver])


    //console.log(page)
    // filter change
    const onChanngefilters = (filters: RecipesFilters) => {
        localStorage.setItem("recipeFilters", JSON.stringify(filters))
        setFilters(filters)
        setFiltersAreOpened(false)
    }

    // scope callback
    const groupTagsCallback = (value: any) => {
        localStorage.setItem("recipeFilters", JSON.stringify({ ...filters, scope: value }))
        setFilters({ ...filters, scope: value })
    }

    const filterCount = filters.themes.length + (filters.category ? 1 : 0)

    return (
        <div>
            {recipesState.data &&
                <div>
                    <FlexJustifyBetween>
                        <div>
                            <TabsGroup selectedValue={filters.scope} data={recipeScopeViewData} selectedCallback={groupTagsCallback} />
                        </div>
                        <div>
                            <OutlineButtonStyle type="secondary" size='s' onClick={() => setFiltersAreOpened(!filtersAreOpened)}>
                                <>
                                    {filterCount > 0 && <FilterCountDot><span>{filterCount}</span></FilterCountDot>}
                                    <FlexLine><FilterIconStyle width="15" height="15" /><span>Filtres</span></FlexLine>
                                </>
                            </OutlineButtonStyle>
                        </div>
                    </FlexJustifyBetween>
                    {filtersAreOpened && <FiltersRecipe filters={filters} callbackFilter={onChanngefilters} />}

                    {recipesState.data.length > 0 ?
                        <div>
                            <span>{`${recipesState.data.length} recette${recipesState.data.length > 0 && "s"}`}</span>
                            {
                                recipesState.data.map((recipe, key) => <RecipeCard key={key} recipe={recipe} />)
                            }
                        </div>
                        : <div>Aucunes recettes de trouvées</div>
                    }
                </div>
            }
            {recipesState.loading && <div>Loading...</div>}
            {recipesState.error && <div>{recipesState.error}</div>}
            {/* {<div ref={loader}>Load more</div>} */}
            
        </div>
    )
}

const FilterIconStyle = styled(FilterIcon)(({theme}) => ({
    marginRight: theme.spacer.xs
}))

const FilterCountDot = styled.div(({ theme }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    right: "-5px",
    top: "-5px",
    borderRadius: "50%",
    backgroundColor: theme.colors.primary.regular,
}))

const OutlineButtonStyle = styled(OutlineButton)({
    position: "relative",
    padding: "0.5rem"
})

const FlexLine = styled.div({
    display: "flex",
    alignItems: "center"
})

const FlexJustifyBetween = styled.div(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacer.m
}))

export default Recipes