import styled from '@emotion/styled'
import React, { useState } from 'react'
import useForm from '../../shared/hooks/useForm'
import InputField from '../forms/InputField'
import validate from '../forms/changePasswordValidationRules'
import Button from '../ui/DS/button'
import { UpdatePassword } from '../../shared/models/user'
import { updatePassword } from '../../shared/api/routes'
import useToaster from '../../shared/hooks/useToaster'
import Toaster from '../ui/toaster'

const ChangePassword: React.FC = () => {

    const defaultValues = {
        oldPassword: "",
        newPassword: ""
    }

    const [loadingState, setLoadingState] = useState(false)
    const { renderToaster, enableTranstion, dispatch } = useToaster()

    const onValidForm = async () => {
        try {
            setLoadingState(true)
            const response = await updatePassword(values)
            setLoadingState(false)
            const json = await response.json()
            if(response.ok) {
                dispatch({type: "renderToaster", payload: {
                    status: "success",
                    msg: json
                }})
            } else {
                dispatch({type: "renderToaster", payload: {
                    status: "error",
                    msg: json
                }})
            }
        } catch (err) {
            setLoadingState(false)
            dispatch({type: "renderToaster", payload: {
                status: "error",
                msg: "Une erreur technique est apparue"
            }})
        }
    }

    const onCloseToaster = () => {
        dispatch({type: "renderToaster", payload: undefined})
    }

    const onMountToaster = () => {
        dispatch({type: "enableTransition", payload: true})
    }

    const { handleChange,
        handleKeyup,
        handleSubmit,
        values,
        errors } = useForm<UpdatePassword>(defaultValues, onValidForm, validate)


    return (

        <form>
            <RowForm>
                <InputField id="oldPassword" name="oldPassword" type="password" value={values.oldPassword} label="Ancien mot de passe" onChange={handleChange} onKeyUp={handleKeyup} error={(errors as any).oldPassword} />
            </RowForm>
            <RowForm>
                <InputField id="newPassword" name="newPassword" type="password" value={values.newPassword} label="Nouveau mot de passe" onChange={handleChange} onKeyUp={handleKeyup} error={(errors as any).newPassword} />
            </RowForm>
            <Button disabled={loadingState} type="submit" styleType="primary" onClick={handleSubmit}>{loadingState ? "Sauvegarde en cours" : "Modifier"}</Button>
            {renderToaster && 
                <Toaster status={renderToaster.status} msg={renderToaster.msg} enableTransition={enableTranstion} onClose={onCloseToaster} onMount={onMountToaster} autoClose={true} />}
        </form>
    )
}

export default ChangePassword

const RowForm = styled.div(({ theme }) => ({
    marginBottom: theme.spacer.m
}))