import * as React from "react";

const FilterIcon = ({
    width = "467",
    height = "489",
    color = "black",
    className

}: React.SVGProps<SVGSVGElement>) => (
    <svg className={className} width={width} height={height} viewBox="0 0 467 489" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.3327 96.6231H254.863C260.734 118.201 275.824 136.092 296.101 145.518C316.382 154.944 339.785 154.944 360.066 145.518C380.347 136.092 395.433 118.202 401.304 96.6231H445.937C453.198 96.6231 459.91 92.7481 463.542 86.4591C467.171 80.17 467.171 72.4201 463.542 66.1311C459.909 59.8419 453.198 55.967 445.937 55.967H401.136C395.265 34.385 380.179 16.4941 359.898 7.06905C339.617 -2.35595 316.214 -2.35675 295.933 7.06905C275.656 16.4949 260.566 34.385 254.695 55.967H20.3347C13.073 55.967 6.36173 59.8421 2.72973 66.1311C-0.899168 72.4202 -0.899168 80.1701 2.72973 86.4591C6.36253 92.7482 13.0737 96.6231 20.3347 96.6231H20.3327ZM328.333 40.6781C337.774 40.7366 346.802 44.5413 353.438 51.256C360.074 57.9669 363.774 67.041 363.723 76.479C363.676 85.9204 359.879 94.952 353.176 101.596C346.469 108.241 337.399 111.948 327.961 111.908C318.519 111.869 309.484 108.084 302.832 101.385C296.183 94.6858 292.465 85.6191 292.492 76.1821C292.539 66.7212 296.339 57.666 303.058 51.006C309.78 44.3498 318.87 40.6351 328.331 40.6781H328.333Z" fill={color} />
        <path d="M445.933 223.853H211.343C205.503 202.259 190.421 184.349 170.14 174.912C149.859 165.475 126.445 165.475 106.163 174.912C85.8807 184.35 70.8037 202.26 64.9597 223.853H20.3267C13.065 223.853 6.35367 227.728 2.72167 234.017C-0.907225 240.306 -0.907225 248.056 2.72167 254.345C6.35448 260.634 13.0657 264.509 20.3267 264.509H65.1277C70.9715 286.103 86.0497 304.013 106.331 313.45C126.612 322.887 150.026 322.888 170.308 313.45C190.59 304.013 205.671 286.102 211.511 264.509H445.931C453.192 264.509 459.904 260.634 463.536 254.345C467.165 248.056 467.165 240.306 463.536 234.017C459.903 227.728 453.192 223.853 445.931 223.853H445.933ZM137.933 279.798C128.499 279.739 119.476 275.939 112.839 269.236C106.206 262.529 102.499 253.466 102.542 244.033C102.581 234.599 106.358 225.568 113.05 218.92C119.741 212.272 128.796 208.545 138.23 208.568C147.663 208.588 156.703 212.345 163.367 219.025C170.031 225.701 173.773 234.748 173.773 244.181C173.757 253.662 169.968 262.747 163.246 269.427C156.519 276.111 147.414 279.841 137.934 279.798H137.933Z" fill={color} />
        <path d="M445.933 391.743H401.132C395.261 370.165 380.175 352.274 359.894 342.848C339.613 333.422 316.21 333.422 295.929 342.848C275.652 352.274 260.562 370.164 254.691 391.743H20.3307C13.069 391.743 6.3577 395.618 2.7257 401.907C-0.903197 408.196 -0.903197 415.946 2.7257 422.235C6.3585 428.524 13.0697 432.399 20.3307 432.399H254.861C260.732 453.981 275.822 471.872 296.099 481.297C316.38 490.723 339.783 490.723 360.064 481.297C380.345 471.871 395.431 453.981 401.302 432.399H445.935C453.196 432.399 459.908 428.524 463.54 422.235C467.169 415.946 467.169 408.196 463.54 401.907C459.907 395.618 453.196 391.743 445.935 391.743H445.933ZM328.333 447.743C318.872 447.806 309.774 444.095 303.052 437.438C296.329 430.782 292.529 421.723 292.493 412.258C292.458 402.797 296.192 393.711 302.864 387.004C309.539 380.297 318.61 376.524 328.071 376.512C337.532 376.504 346.61 380.258 353.301 386.95C359.988 393.641 363.739 402.723 363.727 412.184C363.668 421.563 359.922 430.543 353.301 437.184C346.68 443.829 337.711 447.598 328.332 447.688L328.333 447.743Z" fill={color} />
    </svg>
)

export default FilterIcon