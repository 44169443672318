
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import useRefMounted from '../../shared/hooks/useRefMounted'
import CloseIcon from '../../styles/svg/CloseIcon'
import { theme } from '../../styles/theme'

type ToasterStatus = "error" | "success" | "info"

interface Toaster {
    msg: string
    status: ToasterStatus
    enableTransition: boolean
    autoClose?: boolean
    onClose: () => void
    onMount: () => void
}

const Toaster: React.FC<Toaster> = ({ msg, status, enableTransition, autoClose = false, onClose, onMount }) => {
    const isMount = useRefMounted()
    let timeout: any
    useEffect(() => {
        if (isMount && !enableTransition) {
            onMount()
        }
        if (isMount && enableTransition && autoClose) {
            timeout = setTimeout(() => onClose(), 4000)
        }
        return () => timeout && clearTimeout(timeout)
    }, [isMount, enableTransition])

    return (
        <ToasterStyled enableTransition={enableTransition} onClick={onClose} status={status}>
            <Label>{msg}</Label>
            <CloseBtn><CloseIcon /></CloseBtn>
        </ToasterStyled>
    )
}

const getBaseStyle = (status: ToasterStatus): object => {
    switch (status) {
        case "success":
            return {
                backgroundColor: theme.colors.success.light,
            }
        case "info":
            return {
                border: `2px solid ${theme.colors.primary.regular}`
            }
        case "error":
            return {
                backgroundColor: theme.colors.alert.light
            }
        default:
            return {
                border: `2px solid ${theme.colors.primary.regular}`
            }
    }
}

const Label = styled.div(({ theme }) => ({
    paddingRight: theme.spacer.s
}))

const CloseBtn = styled.div(({ theme }) => ({
}))

const ToasterStyled = styled.div<{ status: ToasterStatus, enableTransition: boolean }>(({ theme, status, enableTransition }) => ({
    position: "fixed",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "bottom ease-in 0.5s",
    bottom: enableTransition ? "3rem" : "-100px",
    right: "1rem",
    padding: theme.spacer.m,
    backgroundColor: theme.colors.alert.light,
    maxWidth: "80%",
    cursor: "pointer",

    ...getBaseStyle(status)
}))

export default Toaster