import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useUiContext } from '../../shared/contexts/uiContext'
import GotToIcon from '../../styles/svg/gotoIcon'
import { mq } from '../../styles/devices'

const HomeHero: React.FC<any> = ({scrollTo}) => {
    
    const {uiState} = useUiContext()

    const gotoAction = () => {
        scrollTo()
    }
     
    return (
        <BackgroundVideo headerHeight={uiState.header?.height}>
            <BgTextPromo>
                <TextPromo>
                    Créez, consultez et partagez vos recettes de cuisine du quotidien sur <HightlightText>marmitouille</HightlightText>
                </TextPromo>
                <GotToIconWrapper onClick={gotoAction}><GotToIcon /></GotToIconWrapper>
            </BgTextPromo>
            <Video autoPlay loop={true}>
                <source type="video/mp4" src="/assets/cooking.mp4" media="(orientation: landscape)" />
                <source type="video/webm" src="/assets/cooking.webm" media="(orientation: landscape)" />
            </Video>
        </BackgroundVideo>
    )
}

export default HomeHero

const BackgroundVideo = styled.div<{headerHeight?: number}>(({headerHeight}) => ({
    position: "relative",
    width: "100%",
    height: headerHeight ? `calc(100vh - ${headerHeight}px)` : "100vh",
    overflow: "hidden"
}))

const Video = styled.video<{headerHeight?: number}>(({headerHeight}) => ({
    width: "100%",
    height: "100%",
    objectFit: "cover"
}))

const BgTextPromo = styled.div(({theme}) => ({
    position: "absolute",
    zIndex: "5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "1rem"
}))

const TextPromo = styled.div(mq({
    fontFamily: '"lato", arial',
    justifySelf: "center",
    marginTop: "auto",
    fontSize: ["2rem", "3rem"],
    color: "white",
    lineHeight: "normal",
    width: ["90%", "50%"]
}))

const GotToIconWrapper = styled.div(({theme}) => ({
    marginTop: "auto",
    cursor: "pointer"
}))

const HightlightText = styled.span(({theme}) => (mq({
    color: theme.colors.primary.regular,
    fontSize: ["3rem", "4rem"]
})))