import React, { useEffect, useState } from 'react'
import useFetch from '../shared/hooks/useFetch'
import { authJsonHeader } from '../shared/utils/utils'
import { useAuthContext } from '../shared/contexts/authContext'
import SubscribtionNav from '../components/subscriptions/subscriptionNav'
import { H2 } from '../styles'
import ResultCard from '../components/subscriptions/resultCard'
import { User } from '../shared/models/user'
import { followValidation } from '../shared/api/routes'


type UserFollowers = {
    waitingFollowers: User[]
    followers: User[]
}

const MySubscribers: React.FC = () => {
    const { state } = useAuthContext()
    const { id: userId } = state

    // init followers
    const [followersState, setFollowersState] = useState<undefined | UserFollowers>(undefined)
    const { data: followers } = useFetch<UserFollowers>(`${process.env.REACT_APP_API_HOST}/user/${userId}/followers`, authJsonHeader)

    // validation
    const followValidationCallback = async (user: User) => {
        const clear = (waitingFollowers: User[], id: string) => {
            return waitingFollowers.filter((waitingFollower) => {
                return waitingFollower._id !== id
            })
        }
        try {
            if (followersState)
                setFollowersState({ waitingFollowers: clear(followersState.waitingFollowers, user._id), followers: [...followersState.followers, user] })
            await followValidation({ id: userId as string, userFollowedId: user._id })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (followers) {
            setFollowersState(followers)
        }
    }, [followers])

    return (
        <div>
            <SubscribtionNav />
            {
                followersState && followersState.waitingFollowers.length > 0 &&
                <div>
                    <H2>Ces personnes souhaitent vous suivre</H2>
                    {followersState.waitingFollowers.map((follower, index) =>
                        <ResultCard ctaLabel="accepter la demande" key={index} ctaCallback={followValidationCallback} {...follower} />
                    )}
                </div>
            }
            <div>
                <H2>Personnes qui vous suivent</H2>
                {
                    followersState && followersState.followers.length > 0 ?
                        followersState.followers.map((follower, index) =>
                            <ResultCard ctaLabel='' disabledCta={true} key={index} {...follower} />
                        )
                        : <div>Aucune</div>

                }
            </div>
        </div>
    )

}

export default MySubscribers