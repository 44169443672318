import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../styles/svg/logo"
import styled from '@emotion/styled'
import { theme } from '../styles/theme'
import ButtonLink from '../components/ui/DS/buttonLink'
import OutlineButtonLink from '../components/ui/DS/outlineButtonLink'
import { mq } from '../styles/devices'
import { useUiContext } from '../shared/contexts/uiContext'
import Footer from '../components/footer'

const Layout: React.FC<any> = ({ children }) => {
    const refHeader = useRef<HTMLElement>(null)
    const {uiDispatch} = useUiContext()
    useEffect(() => {
        const headerHeight = refHeader.current?.clientHeight
        uiDispatch({type: "SET_HEADER", payload: {height: headerHeight}})
    }, [])
    return (
        <LayoutStyle>
            <HeaderStyle ref={refHeader}>
                <NavStyle>
                    <LogoStyle>
                        <Link to="/home"><Logo /></Link>
                    </LogoStyle>
                    <div>
                        <ButtonLinkStyle type="primary" to="/connexion">Connexion</ButtonLinkStyle>
                        <OutlineButtonLinkStyle type="secondary" to="/inscription">Inscription</OutlineButtonLinkStyle>
                    </div>
                </NavStyle>
            </HeaderStyle>
            <div>
                {children}
            </div>
            <Footer />
        </LayoutStyle>
    )
}

const LogoStyle = styled.div(mq({
    width: ["60px", "80px", "80px"],
    "& svg": {
        width: "100%",
        height: "auto"
    }
}))

const ButtonLinkStyle = styled(ButtonLink)({
    fontSize: "0.85rem",
    marginRight: theme.spacer.s
})

const OutlineButtonLinkStyle = styled(OutlineButtonLink)({
    fontSize: "0.85rem",
})

const NavStyle = styled.nav({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
})

const HeaderStyle = styled.header({
    position: "sticky",
    padding: theme.spacer.s,
    borderBottom: `1px solid ${theme.colors.grey.light}`,
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    zIndex: 50
})

const LayoutStyle = styled.div({
    position: "relative",
    minHeight: "100vh",
    paddingBottom: "100px"
})

export default Layout