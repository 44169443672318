import styled from '@emotion/styled'
import React from 'react'
import { mq } from '../../styles/devices'

const CenterPageWrapper: React.FC = ({ children }) => {
    return (
        <CenterPageWrapperStyle>
            {children}
        </CenterPageWrapperStyle>
    )
}

const CenterPageWrapperStyle = styled.div(mq({
    width: ["90%", "75%", "50%"],
    margin: "2rem auto 0 auto"
}))

export default CenterPageWrapper