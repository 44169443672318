import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'

type StyleType = "primary" | "secondary" | "tertiary" | "alert"
type Size = "xs" | "s" | "m"

interface Button {
    styleType: StyleType
    type?: 'submit' | 'reset' | 'button'
    size?: Size
    onClick: (event: any) => void
    children: React.ReactChild
    className?: string
    disabled?: boolean
}

const Button: React.FC<Button> = ({ children, onClick, styleType, className, size, disabled = false, type="submit" }) => {
    return (
        <ButtonStyled size={size} styleType={styleType} type={type} disabled={disabled} onClick={onClick} className={className}>{children}</ButtonStyled>
    )
}

const getColor = (type: StyleType) => {
    switch (type) {
        case "primary": return theme.colors.secondary.regular
        case "secondary": return "white"
        case "tertiary": return "white"
        case "alert": return "white"
        default: return theme.colors.secondary.regular
    }
}

const getSize = (type: Size | undefined): string => {
    switch (type) {
        case "xs": return theme.text.size.xs
        case "s": return theme.text.size.s
        case "m": return theme.text.size.m
        default: return theme.text.size.m
    }
}

const baseButtonStyle = styled.button(({theme}) => ({
    padding: "0.5rem 1rem",
    borderRadius: theme.radius.regular,
    transition: "all 0.2s ease-in ",
    textDecoration: "none",
    display: "inline-block",
    color: theme.colors.secondary.regular,
    cursor: "pointer",
    lineHeight: "normal",
    border: "2px solid transparent"
}))

const ButtonStyled = styled(baseButtonStyle)<Button>(({disabled, theme, styleType, size}) => ({
    backgroundColor: theme.colors[styleType].regular,
    opacity: disabled ? "0.5" : "1",
    color: getColor(styleType),
    fontSize: getSize(size),
    ":hover": {
        color: getColor(styleType),
        backgroundColor: theme.colors[styleType].light
    }
}))

export default Button