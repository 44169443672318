import * as React from "react";

const ProfilIcon = ({
  width = "50px",
  height = "50px",
  className = undefined
}: React.SVGProps<SVGSVGElement> & { className?: string }) => (
  <svg width={width} height={height} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 472.615 472.615">
      <g>
        <circle cx="236.308" cy="117.504" r="111.537" />
      </g>
      <g>
        <path d="M369,246.306c-1.759-1.195-5.297-3.493-5.297-3.493c-28.511,39.583-74.993,65.402-127.395,65.402
			c-52.407,0-98.894-25.825-127.404-65.416c0,0-2.974,1.947-4.451,2.942C41.444,288.182,0,360.187,0,441.87v24.779h472.615V441.87
			C472.615,360.549,431.538,288.822,369,246.306z"/>
      </g>
  </svg>


)

export default ProfilIcon
