import React from 'react'
import { Step } from '../../shared/models/recipe'
import StepField from './StepField'

interface RecipeStepProps {
    steps: Step[]
    addStepCallback: () => void
    stepContentCallback: (step: Step) => void
    removeStepCallback: (position: number) => void

}

const RecipeStep: React.FC<RecipeStepProps> = ({ steps, addStepCallback, stepContentCallback, removeStepCallback }) => {
    return (
        <div>
            {steps.map((step: Step) =>
                <div className="d-flex align-items-center mb-2" key={step.position}>
                    <StepField step={step} stepContentCallback={stepContentCallback} />
                    {steps.length > 1 && <i onClick={() => removeStepCallback(step.position)} className="bi-x"></i>}
                </div>
            )}
            <span className="link" onClick={addStepCallback}>+ Ajouter une étape</span>
        </div>
    )
}

export default RecipeStep