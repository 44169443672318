import * as React from "react";

const CookingTimeIcon = ({
  width = "25",
  height = "25",
  color,

}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.59375 12.2188H19.4062V20.3646C19.4062 21.4235 18.5485 22.2812 17.4896 22.2812H5.51042C4.45146 22.2812 3.59375 21.4235 3.59375 20.3646V12.2188Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.4062 13.6562H22.2812" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.59375 13.6562H0.71875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.59375 12.2187C3.59375 9.04283 7.13383 7.90625 11.5 7.90625C15.8662 7.90625 19.4062 9.04283 19.4062 12.2187" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.6562 17.9688H15.0937" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 5.03125V7.90625" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.9691 0.71875C19.3462 3.47204 15.9077 3.00054 17.9691 5.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.98903 0.71875C6.36616 3.47204 2.92766 3.00054 4.98903 5.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default CookingTimeIcon
