import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import validate from '../components/forms/signinValidationRules'
import useFetch from '../shared/hooks/useFetch'
import useForm from '../shared/hooks/useForm'
import { useAuthContext } from '../shared/contexts/authContext'
import { authJsonHeader } from '../shared/utils/utils'
import Button from "../components/ui/DS/button"
import InputField from '../components/forms/InputField'
import InfoBlock from '../components/ui/DS/infoBlock'
import styled from '@emotion/styled'
import LoadingInline from '../components/ui/DS/loadingInline'
import TextLink from '../components/ui/DS/textLink'
import CenterPageWrapper from '../components/ui/centerPageWrapper'

const Signin: React.FunctionComponent = () => {

    const history = useHistory()
    const redirectState = history.location.state as { from: string }
    const { dispatch } = useAuthContext()

    const defaultValue = {
        email: '',
        password: ''
    }

    const defaultPostData = {
        url: "",
        options: {},
    }

    const [postData, setPostData] = useState(defaultPostData)

    const validFormCallback = () => {
        setPostData({
            ...postData,
            url: `${process.env.REACT_APP_API_HOST}/signin`,
            options: {
                ...authJsonHeader,
                method: "POST",
                body: JSON.stringify(values)
            },
        })
    }


    const { loading, data, error } = useFetch<any>(postData.url, postData.options)
    const { values, errors, handleChange, handleKeyup, handleSubmit } = useForm(
        defaultValue,
        validFormCallback,
        validate,
    )

    useEffect(() => {
        if (data !== undefined) {
            dispatch({
                type: "SET_AUTH",
                payload: data
            })
            history.push(redirectState && redirectState.from ? redirectState.from : '/recettes')
        } else {
            if (error) {
                setPostData(defaultPostData)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error])
    return (
        <CenterPageWrapper>
        <form>
            <div>
                <InputField
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    className="mb-2"
                    value={values.email}
                    onKeyUp={handleKeyup}
                    onChange={handleChange}
                    error={(errors as any).email}
                />
            </div>
            <div>
                <InputField
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    className="mb-2"
                    value={values.password}
                    onKeyUp={handleKeyup}
                    onChange={handleChange}
                    error={(errors as any).password}
                />
            </div>
            <div style={{ margin: "20px 0px" }}>
                <Button styleType="primary" onClick={handleSubmit}>Se connecter</Button>
                {error && <InfoBlockStyled>{error as any}</InfoBlockStyled>}
                {loading && <LoadingInline />}
            </div>
            <TextLink to="/password">Mot de passe oublié ?</TextLink>
        </form>
        </CenterPageWrapper>
    )
}

const InfoBlockStyled = styled(InfoBlock)(({ theme }) => ({
    marginTop: theme.spacer.m,
}))

export default Signin