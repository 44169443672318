import React, { useState, useEffect } from 'react'
import { User } from '../../shared/models/user'
import useDebounceSearch from '../../shared/hooks/useDebounceSearch'
import ResultCard from './resultCard'
import { authJsonHeader } from '../../shared/utils/utils'
import useFetch from '../../shared/hooks/useFetch'
import { useAuthContext } from '../../shared/contexts/authContext'
import LoadingInline from '../ui/DS/loadingInline'
import InputSearch from '../ui/DS/inputSearch'

interface SearchUser {
    clear: boolean
    subscriptionCallback: (user: User) => void
}

const SearchUser: React.FC<SearchUser> = ({ clear, subscriptionCallback }) => {
    const { state } = useAuthContext()
    const { id: userId } = state

    // search
    const [searchUrl, setSearchUrl] = useState("")
    const { data: resultSearch, error: resultSearchError, loading: resultSearchLoading } = useFetch<User[]>(searchUrl, authJsonHeader)

    const callbackSearch = (value: string) => {
        setSearchUrl(`${process.env.REACT_APP_API_HOST}/users/search?s=${value}&id=${userId}`)
    }

    const clearSearch = () => {
        setSearch({
            lock: false,
            value: ""
        })
    }

    useEffect(() => {
        if (clear)
            clearSearch()
    }, [clear])



    const { search, setSearch, handleChange } = useDebounceSearch(1500, callbackSearch)
    
    return (
        <div>
            <InputSearch id="search" type="text" name="search" onChange={handleChange} onClear={clearSearch} value={search.value} />
            {resultSearchError && <p>{resultSearchError}</p>}
            {resultSearchLoading && <LoadingInline />}
            {resultSearch && search.value !== "" ?
                resultSearch.length > 0 ?
                    <div>
                        {resultSearch.map((user, index) => {
                            return <ResultCard disabledCta={false} ctaLabel="s'abonner" {...user} ctaCallback={subscriptionCallback} key={index} />
                        })}
                    </div>
                    : <div>
                        <p>Aucun utilisateur n'a été trouvé</p>
                    </div>
                : null
            }
        </div>
    )
}

export default SearchUser