import React, {useState, useEffect} from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

export interface InputFieldProps {
  inputRef?: React.RefObject<HTMLInputElement>
  name: string
  id: string
  type: "text" | "email" | "password" | "number"
  label?: string
  className?: string
  value?: string | number
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  error?: string
}


const InputField: React.FunctionComponent<InputFieldProps> = (props) => {
  const theme = useTheme()
  const { name, id, type, label, value, placeholder, className, onChange, onKeyUp, error, inputRef } = props

  const [transitionReady, setTransitionReady] = useState(false)

  useEffect(() => {
    if(error && !transitionReady)
      setTimeout(() => { setTransitionReady(true) }, 100)
  }, [error])

  

  const Label = styled.label({
    display: "inline-block",
    fontSize: theme.text.size.m
  })

  
  return (
    <div className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputFieldStyle
        ref={inputRef}
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={value}
      />
      {error && <Error transitionReady={transitionReady}>{error}</Error>}
    </div>
  )
}

const Error = styled.p<{transitionReady: boolean}>(({theme, transitionReady}) => ({
  color: theme.colors.alert.regular,
  transition: "all 1s ease",
  opacity: transitionReady ? 1 : 0
}))

const InputFieldStyle = styled.input(({theme}) => ({
  display: "block",
  width: "100%",
  padding: "0.375rem 0.75rem",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: "1.5",
  color: theme.colors.secondary.regular,
  backgroundColor: "#fff",
  border: `1px solid ${theme.colors.primary.regular}`,
  appearance: "none",
  borderRadius: theme.radius.light,
  outline: "none",
  "&:focus": {
    borderColor: theme.colors.secondary.regular
  }
}))


export default InputField
