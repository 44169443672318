/**
 * @throws ErrorObject
 */

 interface HttpResponse<T> extends Response {
    parsedBody?: T
}

 const http = async <T>(request: RequestInfo): Promise<HttpResponse<T>> => {
    const response: HttpResponse<T> = await fetch(request)
    try {
      response.parsedBody = await response.json()
    } catch (err) {
        console.log("http error", err)
    }
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  }

  export const get = async <T>(
    path: string,
    args: RequestInit = { method: "get" }
  ): Promise<HttpResponse<T>> => {
    return await http<T>(new Request(path, args))
  }

  
// export async function get<TResponse>(
//     url: string,
//     options: any = {},
// ): Promise<TResponse> {
//     try {
//         const response  = await fetch(url, { ...options, method: 'get' }) as any
//         if (response.ok) {
//             return await response.json()
//         }
//     } catch (err) {
//         console.log(err)
//     }
// }

export async function post<TResponse>(
    url: string,
    data: BodyInit,
    options: any = {},
): Promise<TResponse> {
    return fetch(
        url,
        {
            ...options,
            method: 'post',
            body: data,
        },
    ) as any
}

export async function put<TResponse>(
    url: string,
    data: BodyInit,
    options: any = {},
): Promise<TResponse> {
    return fetch(
        url,
        {
            ...options,
            method: 'put',
            body: data,
        },
    ) as any
}


export async function del<TResponse>(
    url: string,
    options: any = {},
): Promise<TResponse> {
    return fetch(
        url,
        {
            ...options,
            method: 'delete',
        }
    ) as any
}