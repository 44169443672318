import React from "react"
import styled from "@emotion/styled"
import { mq } from "../styles/devices"
import { H1, H2 } from "../styles"

const Cgv: React.FC = () => {
    return (
        <Wrapper>
            <H1>Mentions légales</H1>
            <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N.,
                il est porté à la connaissance des utilisateurs et visiteurs, ci-après l""Utilisateur", du site https://marmitouille.com , ci-après le "Site", les présentes mentions légales</p>
            <p>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</p>
            <p>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».
            </p>
            <H2>Éditeur et responsable de publication</H2>
            <p>L’édition et la direction de la publication du Site est assurée par Falguier Maxime, dont l'adresse de contact est contact@marmitouille.com.
                ci-après l'"Editeur".</p>
            <H2>Hébergement</H2>
            <p>heroku.com</p>
            <p>415 Mission Street<br />Suite 300<br />San Francisco, CA<br />94105</p>
            <p>Cet hébergeur détient à ce jour les informations personnelles concernant l’auteur de ce site.</p>
            <H2>Accès au site</H2>
            <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
                En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>
            <H2>Collecte de données</H2>
            <p>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de
                la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux
                libertés.
            </p>
            <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
                L'Utilisateur exerce ce droit par email à à l'adresse contact@marmitouille.com</p>
            <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires
                telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil</p>
            <H2>Responsabilité dans l’utilisation du service</H2>
            <p>L’utilisateur s’engage à ne pas perturber le fonctionnement du service offert sur le Site de quelque manière que ce soit.
                En particulier, l’utilisateur s’engage à s’abstenir de poster des contenus illicites, diffamants ou insultants sur tout ou partie du site. </p>
            <p>Tout contenu à caractère injurieux, diffamatoire, xénophobe, raciste, antisémite, pornographique, homophobe, sexiste,
                d’incitation à la haine, à la violence ou en général tout sujet contraire à la loi et aux valeurs humanistes n’est pas accepté.
                L’administrateur du site se réserve le droit de supprimer sans avertissement tout contenu dérogeant à cette règle.</p>
            <H2>Cookies</H2>
            <p>L’utilisateur est informé que des cookies peuvent s’installer sur son navigateur.
                Dans tous les cas l’utilisateur peut librement effacer ses cookies de son navigateur.</p>
        </Wrapper>
    )
}

const Wrapper = styled.div(mq({
    maxWidth: "80%",
    margin: "2rem auto 0 auto"
}))

export default Cgv