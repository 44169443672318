// to do : put this in api
export const unitData = [{
    value: "",
    label: "-",
    name: ""
}, {
    value: "c_s",
    label: "c à s",
    name: "cuillière à soupe"
}, {
    value: "c_c",
    label: "c à c",
    name: "cuillière à café"
}, {
    value: "ml",
    label: "mL",
    name: "Mililitre"
}, {
    value: "cl",
    label: "cL",
    name: "centilitre"
}, {
    value: "l",
    label: "L",
    name: "litre"
}, {
    value: "g",
    label: "gr",
    name: "gramme"
}, {
    value: "kg",
    label: "kg",
    name: "kilo"
}]

type options = {
    value: string
    label: string
}
export const categoryData: readonly options[] = [
    {
        value: "",
        label: "Toutes"
    },
    {
        value: "starter",
        label: "Entrée"
    },
    {
        value: "dish",
        label: "Plat"
    },
    {
        value: "dessert",
        label: "Dessert"
    },
    {
        value: "aperitif",
        label: "Apéro"
    }
]

export const themeData = [
    {
        key: "allday",
        name: "Pour tous les jours"
    },
    {
        key: "celebration",
        name: "Festif"
    },
    {
        key: "family",
        name: "Classique des familles"
    }
]

// scope recipe view
export const recipeScopeViewData = [{
    key: "all",
    name: "Toutes"
},
{
    key: "mine",
    name: "Les miennes"
},
{
    key: "others",
    name: "Celles des copains"
}]