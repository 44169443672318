import { useEffect, useReducer, useRef } from 'react'

interface State<T> {
    data?: T
    error?: Error
    loading: boolean
}

type Fetch<T> = State<T>

type Cache<T> = { [url: string]: T }

type Action<T> =
    | { type: 'loading'; }
    | { type: 'fetched'; payload: T }
    | { type: 'error'; payload: Error }

// to do : remove any on option ()
function useFetch<T = unknown>(url: string, options?: any, cacheReq: boolean = false): Fetch<T> {
    const cache = useRef<Cache<T>>({})

    const initialState: State<T> = {
        data: undefined,
        error: undefined,
        loading: false,
    }

    const fetchReducer = (state: State<T>, action: Action<T>): State<T | any> => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, loading: true }
            case 'fetched':
                return { ...initialState, data: action.payload }
            case 'error':
                return { ...initialState, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)


    useEffect(() => {
        if (!url) return

        const abortController = new AbortController()
        const fetchData = async () => {
            dispatch({ type: 'loading' })

            if (cacheReq && cache.current[url]) {
                dispatch({ type: 'fetched', payload: cache.current[url] })
                return
            }
            try {
                const response = await fetch(url, { ...options, signal: abortController.signal }) as any
                const responseJson = await response.json()
                // to do: remove window location and use callback
                if (response.status === 401 || response.status === 403) {
                    window.location.href = "/connexion"
                }
                if (!response.ok) {
                    throw responseJson
                }
                if (cacheReq)
                    cache.current[url] = responseJson as T
                dispatch({ type: 'fetched', payload: responseJson })
            } catch (error) {
                dispatch({ type: 'error', payload: error as Error })
            }
        }

        fetchData()

        return () => {
            abortController.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, options?.body])
    return state
}

export default useFetch
