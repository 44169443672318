export const theme = {
    colors: {
        primary: {
            light: "#FFE58A",
            regular: "#FFC600"
        },
        secondary: {
            light: "#8D8D8D",
            regular: "#383838"
        },
        tertiary: {
            light: "#C5A06A",
            regular: "#C77802"
        },
        success: {
            light: "#94EC8D",
            regular: "#94EC8D"
        },
        alert: {
           light: "#FF7A92",
           regular: "#FF264D"
        },
        grey: {
            light: "#E1E1E1"
        }
    },
    radius: {
        light: "5px",
        regular: "10px"
    },
    boxShadow: {
        light: "2px 2px 10px #8D8D8D",
    },
    text: {
        size: {
            xs: "0.5rem",
            s: "0.75rem",
            m: "1rem",
            l: "1.25rem",
            xl: "1.5rem"
        }
    },
    spacer: {
        xs: "0.5rem",
        s: "0.75rem",
        m: "1rem",
        l: "1.25rem",
        xl: "1.5rem",
        xxl: "1.75rem",
        xxxl: "2rem"
    }
}