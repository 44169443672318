import React, { useState } from 'react'
import useFetch from '../shared/hooks/useFetch'
import InputField from '../components/forms/InputField'
import validate, { SignupError } from '../components/forms/signupValidationRules'
import useForm from '../shared/hooks/useForm'
import { authJsonHeader } from '../shared/utils/utils'
import Button from '../components/ui/DS/button'
import InfoBlock from '../components/ui/DS/infoBlock'
import styled from '@emotion/styled'
import { theme } from '../styles/theme'
import LoadingInline from '../components/ui/DS/loadingInline'
import { mq } from '../styles/devices'


const Signup: React.FunctionComponent = (props: any) => {
    const userSponsorId = props.match.params.userSponsorId


    const defaultValue = {
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        password: ''
    }

    const validFormCallback = () => {
        setPostData({
            url: `${process.env.REACT_APP_API_HOST}/signup`,
            options: {
                ...authJsonHeader,
                method: "POST",
                body: JSON.stringify(
                    userSponsorId ? {...values, userSponsorId } : values
                )
            }
        })
    }


    const [postData, setPostData] = useState({
        url: "",
        options: {}
    })

    const { values, errors, handleChange, handleKeyup, handleSubmit } = useForm(
        defaultValue,
        validFormCallback,
        validate,
    )

    const { loading, data, error } = useFetch<string>(postData.url, postData.options)

    return (
        <FormStyle>
            <div>
                <InputField
                    id="username"
                    label="Pseudo *"
                    type="text"
                    name="username"
                    className="mb-2"
                    value={values.username}
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    error={(errors as SignupError).username}
                />

                <InputField
                    id="firstname"
                    label="Prénom"
                    type="text"
                    name="firstname"
                    className="mb-2"
                    value={values.firstname}
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                />

                <InputField
                    id="lastname"
                    label="Nom"
                    type="text"
                    name="lastname"
                    className="mb-2"
                    value={values.lastname}
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                />

                <InputField
                    id="email"
                    label="Email *"
                    type="email"
                    name="email"
                    className="mb-2"
                    value={values.email}
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    error={(errors as SignupError).email}
                />

                <InputField
                    id="password"
                    label="Password *"
                    type="password"
                    name="password"
                    className="mb-2"
                    value={values.password}
                    onChange={handleChange}
                    onKeyUp={handleKeyup}
                    error={(errors as SignupError).password}
                />

                <ButtonStyle styleType="primary" onClick={handleSubmit}>S'inscrire</ButtonStyle>
                {error && <InfoBlockStyled>{error as any}</InfoBlockStyled>}
                {loading && <LoadingInline />}
                {data && <p>{data}</p>}

            </div>
        </FormStyle>
    )
}

const ButtonStyle = styled(Button)({
    marginTop: theme.spacer.s
})
const InfoBlockStyled = styled(InfoBlock)(({theme}) => ({
    marginTop: theme.spacer.m,
}))

const FormStyle = styled.form(mq({
    width: ["90%", "75%", "50%"],
    margin: "2rem auto 0 auto"
}))
export default Signup

