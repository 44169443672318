import styled from '@emotion/styled'
import React from 'react'
import { H1 } from '../../styles'
import { mq } from '../../styles/devices'

const Block: React.FC = ({ children }) => {
    return (
        <BlockStyle>{children}</BlockStyle>
    )
}

export const Howtowork: React.FC<any> = ({ data }) => {
    return (
        <SectionWrapper>
            <ContentCenter>
                <H1>Marmitouille comment ça fonctionne !</H1>
                <FlexLine>
                    {
                        data.map((item: any, index: number) => <Block key={index}>{item.step}</Block>)
                    }
                </FlexLine>
            </ContentCenter>
        </SectionWrapper>
    )
}

export default Howtowork
const SectionWrapper = styled.div(({ theme }) => ({
    padding: `${theme.spacer.l} 0`
}))

const ContentCenter = styled.div(() => ({
    maxWidth: "80%",
    margin: "0 auto"
}))

const FlexLine = styled.div({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
})

const BlockStyle = styled.div(({ theme }) => (mq({
    padding: theme.spacer.s,
    backgroundColor: theme.colors.grey.light,
    width: ["100%", "30%"],
    marginBottom: [theme.spacer.m, "0"]
})))

