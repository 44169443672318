export const authJsonHeader = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': localStorage.getItem('csrfToken')
    }
}

export const filterObjectByKey = (object: any, keyArg: string) => {
  return Object.keys(object)
    .filter((key) => key.includes(keyArg))
    .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: object[key]
        })
  }, {})
}

export const formDataToJson = (formData: FormData) => {
  let object = {} as any
  formData.forEach((value, key) => object[key] = value)
  console.log(JSON.stringify(object))
  return JSON.stringify(object)
}

export const getCsrfToken = async () => {
  try {
    const response = await fetch('/api/csrf-token')
    const responseJson = await response.json()
    if (!response.ok) {
      throw responseJson
    }
    localStorage.setItem('csrfToken', responseJson.csrfToken)
  } catch (e) {
    console.log(e)
  }
}

export const getCookie = (cname: string) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  export const killCookie = (cname: string) => {
    document.cookie = `${cname}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
  }