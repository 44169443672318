import { theme } from '../styles/theme'
import styled from '@emotion/styled'

export const H1 = styled.h1({
    fontFamily: "oswald",
    fontSize: theme.text.size.xl,
    marginBottom: theme.spacer.xxl
})

export const H2 = styled.h2({
    fontFamily: "oswald",
    fontSize: theme.text.size.l,
    marginBottom: theme.spacer.l
})

export const H3 = styled.h3({
    fontFamily: "oswald",
    fontSize: theme.text.size.m,
    marginBottom: theme.spacer.m
})
