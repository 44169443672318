import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { RecipeWithAuthor } from '../../shared/models/recipe'
import { H2 } from '../../styles'
import { mq } from '../../styles/devices'
import TextBold from '../ui/DS/textBold'
import RecipeTimeIcon from '../../styles/svg/recipeTimeIcon'
import { theme } from '../../styles/theme'
import CookingTimeIcon from '../../styles/svg/cookingTimeIcon'
import { S3_BASE_URL } from "../../shared/config"
import CookingPlaceholderIcon from '../../styles/svg/cookingPlaceholder'

interface RecipeCardI {
    recipe: RecipeWithAuthor
}

const RecipeCard: React.FC<RecipeCardI> = ({ recipe }) => {
    return (
        <Card>
            <ImgBlock>
                <Link to={`recette/${recipe._id}`}>
                    {recipe.files && recipe.files.length > 0 && recipe.files[0].thumb ?
                        <img width="100%" src={`${S3_BASE_URL}/${recipe.files[0].thumb}`} alt={recipe.name} />
                        : <CookingPlaceholderWrapper><CookingPlaceholderIcon width="100%" height="100%" /></CookingPlaceholderWrapper>
                    }
                </Link>
            </ImgBlock>
            <ContentBlock>
                <H2><Link to={`recette/${recipe._id}`}>{recipe.name}</Link></H2>
                <div>{recipe.category}</div>
                <AuthorText><span>Mijoté par : {recipe.author.username}</span></AuthorText>
                <FlexWrapperCenter>
                    <FlexWrapperCenter><RecipeTimeIcon color={theme.colors.secondary.regular} /><span>{recipe.recipeTime} min</span></FlexWrapperCenter>
                    <FlexWrapperCenter><CookingTimeIcon color={theme.colors.secondary.regular} /><span>{recipe.cookingTime} min</span></FlexWrapperCenter>
                </FlexWrapperCenter>
            </ContentBlock>
        </Card>

    )
}

const AuthorText = styled(TextBold)(({theme}) => ({
    marginBottom: theme.spacer.m,
    fontSize: theme.text.size.s,
    display: "block"
}))

const FlexWrapperCenter = styled.div({
    display: "flex",
    alignItems: "center",
    marginRight: "10px"
})
const Card = styled.div(({ theme }) => ({
    display: "flex",
    width: "100%",
    flexFlow: "wrap",
    marginBottom: theme.spacer.xl
}))

const CookingPlaceholderWrapper = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #e1e1e1",
    borderRadius: "5px",
    padding: "1rem"
})

const ImgBlock = styled.div(
    mq({
        marginBottom: ["1rem", 0],
        width: ["100%", "25%"],
    })
)

const ContentBlock = styled.div(
    mq({
        width: ["100%", "75%"],
        paddingLeft: [0, "10px"]
    })
)

export default RecipeCard