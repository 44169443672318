import React, { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useClickOutside from '../../shared/hooks/useClickOutside'
import { useAuthContext } from '../../shared/contexts/authContext'
import useFetch from '../../shared/hooks/useFetch'
import ProfilPicture from './DS/profilPicture'
import styled from '@emotion/styled'
import { useUiContext } from '../../shared/contexts/uiContext'


const DropdownProfil: React.FC = () => {
    const { state, dispatch } = useAuthContext()
    const dropdownRef = useRef<HTMLDivElement>(null)
    const {uiState} = useUiContext()
    const {isOpened, setIsOpened, handleclick} = useClickOutside(dropdownRef)
    const location = useLocation()

    // logout
    const [logoutUrl, setLogoutUrl] = useState('')
    const {data: dataLogout, error: errorLogout} = useFetch(logoutUrl)    
    
    const logout = () => {
        setLogoutUrl('/api/logout')
    }
    useEffect(() => {
        if(dataLogout && !errorLogout) {
            dispatch({
                type: "LOGOUT_AUTH"
            })
        }
    }, [dataLogout])

    useEffect(() => {
        setIsOpened(false)
    }, [location])
   
    return (
        <div ref={dropdownRef}>
            <ProfilPictureWrapper onClick={handleclick}>
                <ProfilPicture width='40px' height='40px' avatar={state.avatar} />
            </ProfilPictureWrapper>
            {isOpened &&
                <ProfilMenu posTop={uiState.header?.height}>
                    <MenuItemStyle>Hi, {state.username}</MenuItemStyle>
                    <MenuItemStyle><Link to="/mon-compte">Mon compte</Link></MenuItemStyle>
                    <MenuItemStyle><Link to="/amis/mesabonnements">Amis</Link></MenuItemStyle>
                    <MenuItemStyle><Link onClick={logout} to="/">Déconnexion</Link></MenuItemStyle>
                </ProfilMenu>
            }
        </div>
    )
}

const ProfilPictureWrapper = styled.div(({theme}) => ({
    cursor: "pointer",
    display: "flex",
}))

const ProfilMenu = styled.div<any>(({theme, posTop}) => ({
    textAlign: "right",
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.colors.grey.light}`,
    width: "100%",
    padding: theme.spacer.s,
    position: "absolute",
    right: 0,
    top: posTop + 1,
    zIndex: 5
}))

const MenuItemStyle = styled.div(({theme}) => ({
    marginBottom: theme.spacer.m,
    "& :last-child": {
        marginBottom: 0
    }
}))

export default DropdownProfil