import styled from '@emotion/styled'
import React from 'react'
import { RecipeTheme } from '../../../shared/models/recipe'
import { theme } from '../../../styles/theme'

interface TabsGroupProps {
    selectedValue: string
    data?: RecipeTheme[]
    selectedCallback: (key: string) => void
    className?: string
}


const TabsGroup = ({ data, selectedValue, selectedCallback, className }: TabsGroupProps) => {
    const handleClick = (key: string) => {
        selectedCallback(key)
    }

    return (
        <Flexline>
            {
                (data && data.length > 0) && data.map((item) =>
                    <Tabs className={className} isSelected={selectedValue === item.key} key={item.key} onClick={() => handleClick(item.key)}>{item.name}</Tabs>
                )
            }
        </Flexline>
    )
}

type TabsProps = {
    isSelected: boolean
}

const Tabs = styled.div<TabsProps>(({ isSelected, theme }) => (
    {
        borderBottom: `2px solid ${isSelected ? theme.colors.primary.regular : "transparent"}`,
        marginRight: "7px",
        fontSize: theme.text.size.m,
        cursor: "pointer",
        "&: last-child": {
            marginRight: 0
        }
    }
))

const Flexline = styled.div({
    display: "flex"
})

export default TabsGroup