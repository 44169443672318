import { useState, useEffect } from 'react'

const defaultSearch = {
    lock: false,
    value: ""
}
interface Search {
    search: typeof defaultSearch
    handleChange: (event: any) => void
    setSearch: (search: typeof defaultSearch) => void
}

const useDebounceSearch = (debounce: number = 1000, callback: (value: string) => void): Search => {
    let searchTimeout: NodeJS.Timeout
    

    const [search, setSearch] = useState(defaultSearch)
    
    const handleChange = (event: any) => {
        //searchCallback(event.target.value)
        if (searchTimeout) {
            window.clearTimeout(searchTimeout)
        }
        setSearch({ value: event.target.value, lock: true })
    }

    const searchRequest = (value: string) => {
        callback(value)
        setSearch({ ...search, lock: false })
    }

    useEffect(() => {
        if (search.lock) {
            searchTimeout = setTimeout(() => {
                searchRequest(search.value)
            }, debounce)

        }
        return () => {
            window.clearTimeout(searchTimeout)
        }
    }, [search])

    
    return {search, setSearch, handleChange}
}

export default useDebounceSearch