import React from 'react'
import styled from '@emotion/styled'


interface InfoBlock {
    children: React.ReactChild
    className?: string
}

const InfoBlock: React.FC<InfoBlock> = ({ children, className }) => {
    return (
        <BlockStyled className={className}>{children}</BlockStyled>
    )
}

const BlockStyled = styled.div(({theme}) => ({
    padding: theme.spacer.xs,
    backgroundColor: theme.colors.alert.light
}))

export default InfoBlock