import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'


interface TextLink {
    to: string,
    children: any
    className?: string
}


const TextLink: React.FC<TextLink> = ({ children, to, className }) => {
    return (
        <TextLinkStyle to={to} className={className}>{children}</TextLinkStyle>
    )
}

const TextLinkStyle = styled(Link)(({theme}) => ({
    color: theme.colors.secondary.regular,
    fontSize: "1rem",
    textDecoration: "underline",
    "& :active, :hover": {
        color: theme.colors.secondary.regular,
    }
}))

export default TextLink

