import React, { useEffect, useState } from 'react'
import InputField from '../components/forms/InputField'
import validate from '../components/forms/recipeValidationRules'
import GroupTags from '../components/ui/DS/groupTags'
import IngredientsList from '../components/recipes/ingredientsList'
import Search from '../components/recipes/searchAutoComplete'
import RecipeStep from '../components/recipes/recipeStep'
import useFetch from '../shared/hooks/useFetch'
import useForm from '../shared/hooks/useForm'
import { Ingredient, Step, Recipe } from '../shared/models/recipe'
import { categoryData, themeData } from '../shared/datas/recipe'
import DragAndDropUpload from '../components/ui/dragAndDropUpload'
import { useAuthContext } from '../shared/contexts/authContext'
import { authJsonHeader } from '../shared/utils/utils'
import { useHistory, useParams } from 'react-router-dom'
import { H1, H2 } from '../styles'
import styled from '@emotion/styled'
import CustomSelect from '../components/ui/DS/select'
import LoadingInline from '../components/ui/DS/loadingInline'
import InfoBlock from '../components/ui/DS/infoBlock'
import Button from '../components/ui/DS/button'
import { S3_BASE_URL } from "../shared/config"


const RecipeEdition: React.FC = () => {
    const { state } = useAuthContext()
    const history = useHistory()
    const { id: userId } = state
    
    interface Params {
        id: string
    }
    const { id } = useParams<Params>()

    const defaultPostRecipe = {
        url: "",
        options: {}
    }
    const [postRecipe, setPostRecipe] = useState(defaultPostRecipe)
    

    // if id req parms -> edition mod
    let recipeUrl = ""
    if (id) {
        recipeUrl = `${process.env.REACT_APP_API_HOST}/recipe/${id}`
    }

    
    // api
    const url = `${process.env.REACT_APP_API_HOST}/recipe/ingredients`
    const { data: recipe } = useFetch<Recipe>(recipeUrl, authJsonHeader)
    const { data: ingredientsList } = useFetch<any>(url, authJsonHeader)
    const { data: resultPost, error, loading } = useFetch<string>(postRecipe.url, postRecipe.options)


    // state
    const [fileList, setFileList] = useState<FileList>()
    const defaultRecipeState = {
        name: "",
        category: "starter",
        nbGuest: 4,
        themes: [],
        ingredients: [],
        steps: [{
            position: 1,
            content: ""
        }],
        recipeTime: 0,
        cookingTime: 0,
        files: [],
    }

    // valid form callback
    const validFormCallback = () => {
        const formData = new FormData()
        if(fileList && fileList.length > 0) {
            for (const file of fileList) {
                formData.append('files', file)
            }
        }
        
        if(userId)
            formData.append('authorId', userId)

        let key : keyof Recipe
        for (key in values) {
            if (key !== "files") {
                if (typeof values[key] === "object") {
                    formData.append(key, JSON.stringify(values[key]))
                } else {
                    formData.append(key, values[key] as string)
                }
            }
        }
        
        // prepare post
        const options = {
            method: "POST",
            body: formData,
            headers: {
                'X-CSRF-Token': localStorage.getItem('csrfToken')
            }
            
        }

        setPostRecipe({
            url: `${process.env.REACT_APP_API_HOST}/recipe`,
            options
        })

    }
    const { values, setValues, errors, handleChange, handleKeyup, handleSubmit } = useForm<Recipe>(
        defaultRecipeState,
        validFormCallback,
        validate,
    )

    // set recipe values for edition mod
    useEffect(() => {
        if (recipe) {
            setValues(recipe)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipe])
    
    const [resultIngredients, setResultIngredients] = useState(undefined)


    // ingredients actions
    const searchIngredient = (search: string) => {
        const result = ingredientsList.filter((value: any) => {
            const ingredientName = value.name
            return ingredientName.toLowerCase().startsWith(search.toLowerCase())
        })
        setResultIngredients((search && search !== "") ? result : [])
    }

    const clearSearch = () => {
        setResultIngredients(undefined)
    }

    const addIngredient = (ingredient: Ingredient) => { 
        const defaultQuantity = { value: 1, unit: '-' }
        setResultIngredients(undefined)
        const ingredientAlreadySelected = values.ingredients.filter((item) => item.id === ingredient.id).length > 0
        if (!ingredientAlreadySelected) {
            setValues({ ...values, ingredients: [...values.ingredients, { id: ingredient.id, name: ingredient.name, quantity: defaultQuantity }] })
        }
    }

    const callbackQuantity = (quantity: { value: number, unit?: string }, id: string) => {
        const ingredients = values.ingredients

        const newIngredients = ingredients.map((ingredient) => {
            if (ingredient.id === id) {
                return { ...ingredient, quantity: { value: quantity.value, unit: quantity.unit } }
            }
            return ingredient
        })

        setValues({ ...values, ingredients: newIngredients })

    }

    const removeIngredient = (id: string) => {
        const newIngredients = values.ingredients.filter((item) => item.id !== id)
        setValues({ ...values, ingredients: newIngredients })
    }

    // theme actions
    const addThemesCallback = (key: string) => {
        const themeIsAlreadySelected = values.themes.includes(key)
        if (themeIsAlreadySelected) {
            const cleanThemes = values.themes.filter((item: string) => item !== key)
            setValues({ ...values, themes: cleanThemes })
        } else {
            setValues({ ...values, themes: [...values.themes, key] })
        }
    }

    // recipe steps
    const addStepCallback = () => {
        setValues({ ...values, steps: [...values.steps, { position: values.steps.length + 1, content: "" }] })
    }

    const removeStepCallback = (position: number) => {
        const steps = values.steps
        const cleanStep = steps.filter((item) => item.position !== position)
        const reorderSteps = cleanStep.map((item, i) => {
            return { ...item, position: i + 1 }
        })
        setValues({ ...values, steps: reorderSteps })
    }

    const stepContentCallback = (step: Step) => {
        const steps = values.steps
        const newSteps = steps.map((item) => {
            if (item.position === step.position) {
                return { position: step.position, content: step.content }
            }
            return item
        })
        setValues({ ...values, steps: newSteps })
    }
    useEffect(() => {
        if(resultPost && !error) {
            history.push("/recettes") 
        }
    }, [resultPost, error])

    const handlechangeCateg = (newValue: any) => {
        setValues({...values, category: newValue.value})
    }

    return (
        <form>
            <div>
                <H1>Ajouter une nouvelle recette</H1>
                <div className="mb-4">
                    <H2>Pour commencer</H2>
                    <div className="mb-2">
                        <InputField id="name" label="Nom de la recette *" type="text" name="name" value={values.name} onChange={handleChange} onKeyUp={handleKeyup} error={(errors as any).name} />
                    </div>
                    <div className="mb-2">
                        <TextdStyle>Type de recette</TextdStyle>
                        <CustomSelect name="category" selectedValue={values.category} options={categoryData} handleChange={handlechangeCateg} />
                    </div>
                    <div className="mb-2">
                        <InputField id="nbGuest" label="Nombre de convives" type="number" name="nbGuest" value={values.nbGuest} onChange={handleChange} onKeyUp={handleKeyup} />
                    </div>
                    <div className="mb-2">
                        <TextdStyle>Et si on devait l'étiqueter...</TextdStyle>
                        <GroupTags selectedValues={values.themes} data={themeData} selectedCallback={addThemesCallback} />
                    </div>
                </div>
                <div className="mb-4">
                    <H2>Ok, on fait comment ?</H2>
                    <TextdStyle>Ingrédients *</TextdStyle>
                    <IngredientsList callback={callbackQuantity} data={values.ingredients} removeIngredient={removeIngredient} />
                    <Search result={resultIngredients} addIngredient={addIngredient} callback={searchIngredient} clearSearch={clearSearch} />
                    {(errors as any).ingredients && <p className="text-danger">{(errors as any).ingredients}</p>}
                </div>
                <div className="mb-4">
                    <TextdStyle>Etapes de la recette *</TextdStyle>
                    <RecipeStep steps={values.steps} addStepCallback={addStepCallback} stepContentCallback={stepContentCallback} removeStepCallback={removeStepCallback} />
                    {(errors as any).steps && <p className="text-danger">{(errors as any).steps}</p>}
                </div>
                <div className="mb-4">
                    <InputField id="recipeTime" type="number" label="Temps de préparation (min)" name="recipeTime" value={values.recipeTime} onChange={handleChange} />
                </div>
                <div className="mb-4">
                    <InputField id="cookingTime" type="number" label="Temps de cuisson (min)" name="cookingTime" value={values.cookingTime} onChange={handleChange} />
                </div>
                <div className="mb-4">
                    <H2>Et pour terminer en beauté</H2>
                    <DragAndDropUpload callback={(files) => { setFileList(files)}}/>
                </div>
                {
                    values.files && values.files.length > 0 &&
                    values.files.map((file, index) => <div key={`img${index}`}><img width="320" height="320" src={`${S3_BASE_URL}/${file.thumb}`} alt="" /></div>)
                }
                <Button styleType="primary" onClick={handleSubmit}>Enregistrer</Button>
                {loading && <LoadingInline />}
                {error && <InfoBlock><span>{error}</span></InfoBlock>}
            </div>
        </form>
    )
}

const TextdStyle = styled.p(({theme}) => ({
    display: "block",
    marginBottom: theme.spacer.s
}))

export default RecipeEdition