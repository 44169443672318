import { useCallback, useEffect, useState } from 'react'



const useClickOutside = (dropdownRef: React.RefObject<any>) => {
    const [isOpened, setIsOpened] = useState(false)
    
    const handleclick = useCallback(() => {
        setIsOpened(!isOpened)
    }, [isOpened])

    const pageClick = useCallback((e: globalThis.MouseEvent) => {
        if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target as Node)){
            setIsOpened(!isOpened)
        }
    }, [isOpened, dropdownRef])

    const escapeDropdown = useCallback((e: KeyboardEvent) => {
        if(e.key === "Escape") {
            setIsOpened(!isOpened)
        }
    }, [isOpened])

    useEffect(() => {
        if (isOpened) {
            window.addEventListener('click', pageClick)
            window.addEventListener('keydown', escapeDropdown)
        }
        return () => {
            window.removeEventListener('click', pageClick)
            window.addEventListener('keydown', escapeDropdown)
        }
    }, [isOpened])

    return {isOpened, setIsOpened, handleclick}
}

export default useClickOutside
