import React, { useEffect, useState } from "react"
import useDragAndDrop from "../../shared/hooks/useSimpleDragAndDrop"

type DragAndDropUploadProps = {
    callback: (files: FileList) => void
}

const DragAndDropUpload: React.FC<DragAndDropUploadProps> = ({callback}) => {
    const maxFilesUpload = 4
    const maxSizePerFile = 3000000

    const {
        dragOver,
        setDragOver,
        onDragOver,
        onDragLeave,
        fileDropError,
        setFileDropError,
    } = useDragAndDrop()

    const [files, setFiles] = useState<FileList>()

    useEffect(() => {
        if(fileDropError === "") {
            callback(files as FileList)
        } else {
            setFiles(undefined)
        }
    }, [fileDropError, files])

    const validFilesUpload = (files: FileList) => {
        const filesTab = Array.from(files)
        if (filesTab.length > maxFilesUpload) {
            setFileDropError(`Merci d'uploader un maximum de ${maxFilesUpload}`)
            return
        }

        filesTab.forEach(file => {
            if (file.type.split("/")[0] !== "image") {
                setFileDropError("Merci d'uploader une image")
                return
            }
            if (file.size > maxSizePerFile) {
                setFileDropError("Merci d'uploader des images de maximum 3mo")
                return
            }
        })
    }

    const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault()
        setDragOver(false)
        const selectedFiles = e.dataTransfer.files as FileList
        setFileDropError('')
        validFilesUpload(selectedFiles)
        setFiles(selectedFiles)
    }

    const handleChange = (e: React.ChangeEvent<any>) => {
        const selectedFiles = e.target.files as FileList
        setFileDropError('')
        validFilesUpload(selectedFiles)
        setFiles(selectedFiles)
    }

    return (
        <div className="upload-area">
            {fileDropError && (
                <span className="file-drop-error">{fileDropError}</span>
            )}
            <label
                htmlFor="file"
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                style={{ border: `${dragOver ? "3px dashed yellowgreen" : ""}` }}
            >
                {files && <div>{
                    Array.from(files).map((file, key) => <h1 className="mb-2" key={key}>{file.name}</h1>)
                }</div>}
                {!files && (
                    <h1 style={{ color: `${dragOver ? " yellowgreen" : ""}` }}>
                        {!dragOver ? "Sélectionner ou glisser déposer vos images ici" : "Déposer ici"}
                    </h1>
                )}
            </label>
            <input type="file" multiple={true} name="file" id="file" onChange={handleChange} />
        </div>
    )
}

export default DragAndDropUpload