import styled from '@emotion/styled'
import React, {useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import { theme } from '../styles/theme'
import { mq } from '../styles/devices'
import Logo from "../styles/svg/logo"
import DropdownProfil from '../components/ui/dropdownProfil'
import { useUiContext } from '../shared/contexts/uiContext'

const LoggedLayout: React.FC = ({ children }) => {
    const refHeader = useRef<HTMLElement>(null)
    const {uiDispatch} = useUiContext()
    useEffect(() => {
        const headerHeight = refHeader.current?.clientHeight
        uiDispatch({type: "SET_HEADER", payload: {height: headerHeight}})
    }, [])
    return (
        <div className="layout">
            <HeaderStyle ref={refHeader}>
                <NavStyle>
                    <LogoStyle>
                        <Link to="/recettes"><Logo /></Link>
                    </LogoStyle>
                    <FlexBlock>
                        <div><Link to="/recettes/edition"><AddStyle>+</AddStyle></Link></div>
                        <div>
                            <DropdownProfil />
                        </div>
                    </FlexBlock>
                </NavStyle>
            </HeaderStyle>

            <MainSectionStyle>
                {children}
            </MainSectionStyle>
        </div>
    )
}
const HeaderStyle = styled.header(({ theme }) => ({
    position: "sticky",
    borderBottom: `1px solid ${theme.colors.grey.light}`,
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    zIndex: 50
}))

const FlexBlock = styled.div({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap"
})

const LogoStyle = styled.div(mq({
    width: ["60px", "80px", "100px"],
    "& svg": {
        width: "100%",
        height: "auto"
    }
}))

const MainSectionStyle = styled.section({
    padding: theme.spacer.s
})

const AddStyle = styled.div(({ theme }) => ({
    fontSize: theme.text.size.xl,
    fontWeight: "bold",
    padding: `0 ${theme.spacer.s}`
}))

const NavStyle = styled.nav({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacer.s
})

export default LoggedLayout