import React from 'react'
import styled from '@emotion/styled'


interface UserCard {
    children: React.ReactChild
    className?: string
}

const UserCard: React.FC<UserCard> = ({ children, className }) => {
    return (
        <UserCardStyle className={className}>{children}</UserCardStyle>
    )
}

const UserCardStyle = styled.div(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.colors.secondary.light}`,
    padding: theme.spacer.s,
    marginBottom: theme.spacer.m
}))

export default UserCard