import React from 'react'
import styled from '@emotion/styled'
import { Quantity } from '../../shared/models/recipe'
import { unitData } from '../../shared/datas/recipe'
import InputField from '../forms/InputField'
import CustomSelect, { OptionType } from '../ui/DS/select'
import { SingleValue, ActionMeta } from 'react-select'

interface IngredientQuantitiesProps {
    id: string
    quantity: Quantity
    callback: (quantity: {value: number, unit?: string}, id: string) => void
}

const IngredientQuantities: React.FC<IngredientQuantitiesProps> = ({ id, quantity, callback }) => {
    return (
        <FlexLine>
            <InputFieldStyle id="quantity" type="number" name={`quantity${id}`} value={quantity.value} onChange={(e) => callback({value: parseInt(e.target.value as string, 10), unit: quantity.unit}, id)} />
            <CustomSelect options={unitData} selectedValue={quantity.unit} name={`unit${id}`} handleChange={(newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => callback({value: quantity.value, unit: newValue?.label}, id)} />
        </FlexLine>
    )
}

const FlexLine = styled.div(() => ({
    display: "flex",
    alignItems: "center"
}))

const InputFieldStyle = styled(InputField)(() => ({
    width: "35%",
    marginRight: "10px"
}))

export default IngredientQuantities