import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Theme } from '../../../theme'

type Types = "primary" | "secondary" | "tertiary"

interface Button {
    type: Types
    to: string
    children: string
    className?: string
}

const OutlineButtonLink: React.FC<Button> = ({ children, to, type, className }) => {

    return (
        <ButtonStyled to={to} className={className} type={type}>{children}</ButtonStyled>
    )
}

const baseButtonStyle = styled(Link)(({theme}) => ({
    padding: "0.5rem 1rem",
    borderRadius: theme.radius.regular,
    transition: "all 0.2s ease-in ",
    textDecoration: "none",
    display: "inline-block",
    color: theme.colors.secondary.regular,
    cursor: "pointer",
    lineHeight: "normal"
}))

const ButtonStyled = styled(baseButtonStyle)<{type: Types}>(({theme, type}) => ({
    backgroundColor: "white",
    color: theme.colors.secondary.regular,
    border: `2px solid ${getColor(type, theme)}`,
    "&:hover": {
        color: theme.colors.secondary.regular
    }
}))



const getColor = (type: Types, theme: Theme, alternativeState?: boolean, ) => {
    switch (type) {
        case "primary": return alternativeState ? theme.colors.primary.light : theme.colors.primary.regular
        case "secondary": return alternativeState ? theme.colors.secondary.light : theme.colors.secondary.regular
        case "tertiary": return alternativeState ? theme.colors.tertiary.light : theme.colors.tertiary.regular
        default: return alternativeState ? theme.colors.primary.light : theme.colors.primary.regular
    }
}

export default OutlineButtonLink