import React, { Suspense } from 'react';
import Signup from './pages/signup'
import Signin from './pages/signin'
import AccountValidation from './pages/accountValidation'
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom"
import Layout from './pages/layout'
import Home from './pages/home'
import ForgotPassword from './pages/forgotPassword';
import Cgv from './pages/cgv';


const UnloggedApp: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Suspense fallback={"loading"}>
            <Switch>
              <Route
                path="/"
                exact={true}
                component={Home}
              />
              <Route
                path="/inscription"
                exact={true}
                component={Signup}
              />
              <Route
                path="/inscription/:userSponsorId"
                exact={true}
                component={Signup}
              />
              <Route
                path="/connexion"
                exact={true}
                component={Signin}
              />
              <Route
                path="/password"
                exact={true}
                component={ForgotPassword}
              />
              <Route
                path="/password/:hash"
                exact={true}
                component={ForgotPassword}
              />
              <Route
                path="/account/:hash"
                exact={true}
                component={AccountValidation}
              />
              <Route
                path="/account/:hash/follow/:userSponsorId"
                exact={true}
                component={AccountValidation}
              />
              <Route
                path="/mentions-legales"
                exact={true}
                component={Cgv}
              />
              <Route
                path="/*"
                render={() => <Redirect to="/" />}
              />
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    </div>
  )
}

export default UnloggedApp
