import React, {
  createContext,
  useContext,
  useReducer
} from 'react'

interface Header {
  height?: number
}

type InitialUiState = {
  header?: Header
}
type UiState = {
  uiState: InitialUiState,
  uiDispatch: React.Dispatch<UiActionTypes>
}

export type UiActionTypes = ActionSetHeader 


type ActionSetHeader = {
  type: "SET_HEADER"
  payload: Header
}

export const initialUiState = {
    header: undefined
}

export const UiContext = createContext<UiState>({
  uiState: initialUiState,
  uiDispatch: () => null
})

const uiReducer = (state: InitialUiState, action: UiActionTypes) => {
  switch (action.type) {
    case 'SET_HEADER':
      return {...state, header: action.payload}
    default:
      return state
  }
}

export const UiProvider: React.FC = ({ children }) => {
  const [uiState, uiDispatch] = useReducer(uiReducer, initialUiState)

  return (
    <UiContext.Provider value={{ uiState, uiDispatch }}>
        <>{children}</>
    </UiContext.Provider>
  )
}

export const useUiContext = () => {
  const context = useContext(UiContext)
  if (context === undefined) {
    throw new Error('useUiContext must be used within a UiContext.Provider')
  }
  return context
}
