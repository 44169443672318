import * as React from "react";

const RecipeTimeIcon = ({
  width = "25",
  height = "25",
  color,

}: React.SVGProps<SVGSVGElement>) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6437 14.0625C16.2302 14.0625 15.8417 13.8677 15.5958 13.5354C14.1927 11.249 13.3958 8.64378 13.2812 5.96357C13.2812 3.28024 15.0302 1.10524 17.1875 1.10524C19.3448 1.10524 21.0937 3.28024 21.0937 5.96357C20.9792 8.64274 20.1823 11.249 18.7802 13.5354C18.5344 13.8667 18.1448 14.0625 17.7323 14.0625H16.6437Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.1875 1.10524V17.1875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.75 22.6562C18.75 23.5187 18.05 24.2187 17.1875 24.2187C16.325 24.2187 15.625 23.5187 15.625 22.6562V17.1875H18.75V22.6562Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.94792 7.03125H8.33333C8.90833 7.03125 9.375 7.49792 9.375 8.07292V16.9271C9.375 17.5021 8.90833 17.9687 8.33333 17.9687H4.94792C4.37292 17.9687 3.90625 17.5021 3.90625 16.9271V8.07292C3.90625 7.49792 4.37292 7.03125 4.94792 7.03125Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.81274 7.03128H5.46899L4.88253 2.72503C4.76899 1.75315 5.46482 0.873985 6.43565 0.760443C7.40649 0.646902 8.2867 1.34273 8.40024 2.31357C8.41586 2.45003 8.41586 2.58857 8.40024 2.72503L7.81274 7.03128Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.81274 17.9688H5.46899L4.88253 22.275C4.76899 23.2469 5.46482 24.126 6.43565 24.2396C7.40753 24.3531 8.2867 23.6573 8.40024 22.6865C8.41586 22.55 8.41586 22.4115 8.40024 22.275L7.81274 17.9688Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default RecipeTimeIcon
