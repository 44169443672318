import { useState, useEffect } from 'react'

const useConfirmationPopin = (isOpen: boolean, popinRef: React.RefObject<any>) => {
    const [popinIsOpen, setPopinIsOpen] = useState(isOpen)
    const pageClick = (e: globalThis.MouseEvent) => {
        if (popinRef.current !== null && !popinRef.current.contains(e.target as Node)){
            setPopinIsOpen(!popinIsOpen)
        }
    }

    const escapeDropdown = (e: KeyboardEvent) => {
        if(e.key === "Escape") {
            setPopinIsOpen(!popinIsOpen)
        }
    }
    useEffect(() => {
        if (popinIsOpen) {
            window.addEventListener('click', pageClick)
            window.addEventListener('keydown', escapeDropdown)
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        return () => {
            window.removeEventListener('click', pageClick)
            window.addEventListener('keydown', escapeDropdown)
            document.body.style.overflow = 'auto'
        }
    }, [popinIsOpen])

    const handleClickPopin = () => {
        setPopinIsOpen(!popinIsOpen)
    }
    return {popinIsOpen, handleClickPopin}
}

export default useConfirmationPopin