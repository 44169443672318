import { RecipesFilters } from '../models/recipe'
import { UpdatePassword } from '../models/user'
import { get, post, put, del } from './http'

const headersCSRF = {
    'X-CSRF-Token': localStorage.getItem('csrfToken') as string
}

const headersJSON = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

// recipes
export const getRecipes = <T>(id: string, filters: RecipesFilters, page: number) => {
    console.log(filters)
    return get<T>(`/api/user/${id}/recipes?scope=${filters.scope}&category=${filters.category}&themes=${filters.themes.toString()}&page=${page}`, {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })
}
    


// forgot password
export const forgotPassword = (data: { email: string }): Promise<Response> =>
    post(`/api/forgotpassword`, JSON.stringify(data), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })
export const forgotPasswordValidation = (data: { password: string, hash: string }): Promise<Response> =>
    post(`/api/forgotpasswordvalidation`, JSON.stringify(data), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })

// myaccount routes
export const createAvatar = (data: FormData): Promise<Response> =>
    post(`/api/user/avatar`, data, {
        headers: {
            ...headersCSRF
        }
    })

export const deleteAvatar = (): Promise<Response> =>
    del(`/api/user/avatar`, {
        headers: {
            ...headersCSRF
        }
    })

export const updatePassword = (data: UpdatePassword): Promise<Response> =>
    post(`/api/updatepassword`, JSON.stringify(data), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })

// followers / following

export const follow = (data: { id: string, userFollowedId: string }): Promise<Response> =>
    put(`/api/user/${data.id}/follow`, JSON.stringify({ userFollowedId: data.userFollowedId }), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })

export const unfollow = (data: { id: string, userFollowedId: string }): Promise<Response> =>
    put(`/api/user/${data.id}/unfollow`, JSON.stringify({ userFollowedId: data.userFollowedId }), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })

export const followValidation = (data: { id: string, userFollowedId: string }): Promise<Response> =>
    put(`/api/user/${data.id}/followvalidation`, JSON.stringify({ userFollowedId: data.userFollowedId }), {
        headers: {
            ...headersCSRF,
            ...headersJSON
        }
    })


