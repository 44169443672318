import React from 'react'
import styled from '@emotion/styled'


interface Textarea {
    value: string
    className?: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}


const Textarea: React.FC<Textarea> = ({ value, className, onChange }) => {
    
    return (
        <TextareaStyle value={value} className={className} onChange={onChange} />
    )
    
}

const TextareaStyle = styled.textarea(({theme}) => ({
    fontFamily: '"lato", arial',
    fontSize: theme.text.size.m,
    border: `1px solid ${theme.colors.primary.regular}`,
    padding: theme.spacer.xs
}))

export default Textarea

