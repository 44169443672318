export type SignupError = {
  email: string,
  username: string,
  password: string
}
export default function validate(values: any): SignupError {
  let errors = {} as any;

  if (!values.email) {
    errors.email = 'Merci de saisir un email'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Merci de saisir un email valide';
  }
  if (!values.username) {
    errors.username = 'Merci de saisir un pseudo'
  }
  if (!values.password) {
    errors.password = 'Merci de saisir un password'
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&#^]{8,})/.test(values.password)) {
    errors.password = 'Le password doit contenir au minimum 8 caractères avec au minimun 1 majuscule, 1 minuscule et un caractère spéciale'
  }
  console.log(errors)
  return errors;
};