import React from 'react'
import Select, { StylesConfig, SingleValue, ActionMeta } from 'react-select'
import { theme } from '../../../styles/theme'


interface CustomSelectProps {
    name: string
    selectedValue?: string
    options: readonly OptionType[]
    className?: string
    handleChange: (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void
}

export type OptionType = {
    label: string
    value: string
    name?: string
};

type IsMulti = false

const CustomSelect: React.FC<CustomSelectProps> = ({ name, selectedValue, options, className, handleChange }) => {
    
    return (
        <Select
            name={name}
            styles={selectStyle}
            value={options.find((option) => option.value === selectedValue)}
            onChange={handleChange}
            options={options}
            customTheme={theme}
            className={className}
        />
    )
    
}
const selectStyle: StylesConfig<OptionType, IsMulti> = {
    control: (provided, {selectProps, isFocused}) => ({
        ...provided,
        borderColor: isFocused ? selectProps.customTheme.colors.secondary.regular : selectProps.customTheme.colors.primary.regular,
        boxShadow: "none"
    }),
    option: (provided, { selectProps, isSelected }) => ({
        ...provided,
        borderBottom: `1px dotted ${selectProps.customTheme.colors.secondary.regular}`,
        color: isSelected ? selectProps.customTheme.colors.primary.regular : selectProps.customTheme.colors.secondary.regular,
        padding: selectProps.customTheme.spacer.s,
        backgroundColor: isSelected ? selectProps.customTheme.colors.secondary.regular : "white"
    }),
    singleValue: (provided, { isDisabled }) => {
        const opacity = isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
}
export default CustomSelect

